import React, { useState } from "react";
import downArrowImg from "../../img/question-downarrow.svg";

const Jobs = () => {
  let [opened, setOpened] = useState(null);

  let availableJobs = [
    {
      title: "Product Designers",
      description:
        "We are looking for a passionate designer that can take on complex problems and into simple and engaging experiences. Does this sounds like someone you know?",
    },
    {
      title: "Front End Developer",
      description:
        "We’re looking for an experienced front end web developer with strong js expertise who strives to do high-quality work and thrives on collaboration and working as part of a team. Does this sounds like someone you know?",
    },
    {
      title: "Back End Developer",
      description:
        "Have several years of practical experience building production-grade backend applications in Node.js. Does this sounds like someone you know?",
    },
    {
      title: "Community Manager",
      description:
        "Savvy with social trends, an exceptional writer with a creative mind and bring a knack for building relationships online—and offline. Does this sounds like someone you know?",
    },
  ];

  return (
    <section id="jobs">
      <div className="jobs mt-48 mb-72">
        <h2>Job openings at redxam</h2>
        <div className="jobs_list">
          {availableJobs.map((job, idx) => {
            return (
              <div
                className={`jobs_list_item ${opened === idx ? "py-3" : ""}`}
                key={`job_${idx}`}
                onClick={() => setOpened((old) => (old === idx ? null : idx))}
              >
                <div className="flex flex-row items-center justify-between w-full">
                  <p>{job.title}</p>
                  <img
                    src={downArrowImg}
                    alt=""
                    className={opened === idx && "transform rotate-180"}
                  />
                </div>
                <p className={opened === idx ? "flex text-xl" : "hidden"} style={{ color: "#333" }}>
                  {job.description}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Jobs;
