import React from "react";
import { Icon, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { Link } from "react-router-dom";
import InternalLayout from "../../../components/layouts/Internal";

const Faq = () => {
  return (
    <InternalLayout>
      <div className="faq">
        <div className="faqHeader">
          <div className="backIcon">
            <Link to="/home" className="unDecoration">
              <Icon>arrow_back</Icon>
            </Link>
          </div>
        </div>
        <div className="text-center settingTitle">FAQs</div>
        <List component="nav" aria-label="main mailbox folders" className="warningList">
          <ListItem>
            <ListItemIcon>
              <span className="listNumber faqListNumber">1</span>
            </ListItemIcon>
            <ListItemText
              primary="Gravida potenti eget cum consectetur blandit enim, dictum
                    fermentum tempor"
              className="listText"
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <span className="listNumber faqListNumber">2</span>
            </ListItemIcon>
            <ListItemText
              primary="RIsus pretium varius varius massa egestas feugiat viverra
                    integer egestas"
              className="listText"
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <span className="listNumber faqListNumber">3</span>
            </ListItemIcon>
            <ListItemText
              primary="Ullamcorper ut pulvinar cursus rhoncus volutpat condimentum
                    pellentesque sed risus"
              className="listText"
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <span className="listNumber faqListNumber">4</span>
            </ListItemIcon>
            <ListItemText
              primary="Aliquam dignissim ut feugiat nunc, malesuada augue sed hac
                    fames"
              className="listText"
            />
          </ListItem>
        </List>
      </div>
    </InternalLayout>
  );
};

export default Faq;
