import React from "react";

// Imgs Imports
import scrollImg from "../../img/mouse.svg";
import scrollWhiteImg from "../../img/mouse-white.svg";
import { Link } from "react-scroll";

const Hero = () => {
  return (
    <section>
      <div className="hero">
        <h1>Get the latest update and Gist on investment from the redxam Blog</h1>
        <p>
          redxam is changing the way the world moves and embraces cryptocurrencies, a gateway to
          more and better business creating a financial solutions platform to make cryptocurrency
          purchases simple and user-friendly.
        </p>
        <Link className="cursor" to="blogPost" spy={true} smooth={true}>
          <img src={scrollImg} alt="Scroll Down" className="dark:hidden" />
          <img src={scrollWhiteImg} alt="Scroll Down" className="hidden dark:block" />
        </Link>
      </div>
    </section>
  );
};

export default Hero;
