import React, { useEffect, useRef, useState } from "react";
import { CircularProgress, Snackbar } from "@material-ui/core";
import { verify } from "../../redux/actions/UserActions";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "@material-ui/lab";
import { useHistory } from "react-router";

const Verify = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [first, setFirst] = useState(true);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);

  const userData = useSelector((state) => {
    return state.user;
  });

  useEffect(() => {
    if (userData.success) {
      setOpen(true);
      setMessage(userData.error);
      setSuccess(true);
      setFirst(false);
    }
    if (!userData.success && userData.error) {
      setOpen(true);
      setMessage(userData.error);
      setSuccess(false);
      setFirst(false);
    }
  }, [userData.success, userData.error]);

  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    const token = query.get("token");
    dispatch(verify(token, history));
  }, [dispatch, history, props.location.search]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="loadingDiv">
      LOADING LOADING LOADING LOADING LOADING LOADING LOADING 
      <Snackbar
        open={open && !first}
        className="toastDiv"
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity={success ? "success" : "error"}>
          {message}
        </Alert>
      </Snackbar>
      <div className="loaderContainer">
        <img src="./assets/images/short_logo.png" />
        <CircularProgress size="100px" thickness={1.5} className="loaderCircle" />
      </div>
    </div>
  );
};

export default Verify;
