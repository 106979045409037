import { useEffect } from "react";
import { useHistory } from "react-router-dom";

export default function NotFound() {
  const history = useHistory();

  useEffect(() => {
    history.push("/");
    // eslint-disable-next-line
  }, []);

  return <span>404</span>;
}
