import { CHANGE_BACKEND } from "../actions/AppActions.js";
import localStorageService from "../../services/localStorageService";

const savedBackend = localStorageService.getItem("backend");
const backend = savedBackend
  ? savedBackend
  : process.env.NODE_ENV === "production"
  ? "prod"
  : "local";

const initialState = {
  backend, // prod or dev or local
};

const appReducer = function (state = initialState, action) {
  switch (action.type) {
    case CHANGE_BACKEND: {
      localStorageService.setItem("backend", action.payload);
      return {
        ...state,
        backend: action.payload,
      };
    }
    default:
      return state;
  }
};

export default appReducer;
