import React, { useState } from "react";
import axios from "axios";
import GetBackendURL from "../../../../constants";

import newsletterImg from "./img/newsletter-img.png";

const Newsletter = () => {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const submitEmail = async () => {
    if (validateEmail(email)) {
      const mutation = `
    mutation {
        createWaitlist(arg: {
          email: "${email}"
        }) {
            success
            message
        }
    }
`;
      const backendURL = GetBackendURL();
      axios.post(backendURL + mutation).then((res) => {
        //console.log(res.data.data.createWaitlist);
        if (res.data.data.createWaitlist.success) {
          //console.log(res.data.data.createWaitlist);
          setSubmitted(true);
        } else {
          setSubmitted(false);
        }
      });
    }
  };

  return (
    <section>
      <div className="newsletter">
        <img src={newsletterImg} alt="newsletter" />
        {submitted ? (
          <>
            <h2>
              Get the latest <br />
              from redxam
            </h2>
            <br />
            <h3>We'll keep you posted!</h3>
          </>
        ) : (
          <>
            <h2>
              Get the latest <br />
              from redxam
            </h2>
            <input
              type="email"
              placeholder="Enter your email address"
              onChange={(e) => setEmail(e.target.value)}
            />
            <button style={{ color: "white" }} onClick={() => submitEmail()}>
              Subscribe to Newsletter
            </button>
          </>
        )}
      </div>
    </section>
  );
};

export default Newsletter;
