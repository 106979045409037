import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// import { isBrowser, isMobile } from "react-device-detect";

// Components imports
import { Container } from "reactstrap";
import MobileNavbar from "../../main/MobileNavbar/MobileNavbar";
import QRCode from "qrcode";

// Imgs Imports
import depositButton from "./img/deposit-button.svg";
import qrImage from "./img/qr.png";
import share from "./img/share.svg";
import greenCheck from "./img/green-check.svg";

const DepositMobile = () => {
  const [paymentMethod, setPaymentMethod] = useState(1);
  const [price, setPrice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [qrCode, setQrCode] = useState("");

  const history = useHistory();

  const user = useSelector((state) => {
    return state.home.user;
  });

  //console.log(user);

  const userDeposits = useSelector((state) => {
    return state.home.home.homepage.deposits;
  });

  const handlePaymentMethod = (i) => {
    setPaymentMethod(i);
  };

  useEffect(() => {
    fetch("https://blockchain.info/ticker")
      .then((res) => res.json())
      .then((data) => {
        setPrice(data.USD.last);
        setLoading(false);
      })
      .catch((error) => {
        //console.log(error);
      });
  }, []);

  let balance = 0;

  userDeposits.forEach((userDeposit) => {
    userDeposit.date = new Date(userDeposit.timestamp).toLocaleString("default", {
      month: "short",
    });
    userDeposit.date += " ";
    userDeposit.date += new Date(userDeposit.timestamp).getDay();
    balance += userDeposit.amount * 0.00000001;
  });

  // useEffect(() => {
  //   async function generateQrCode() {
  //     try {
  //       const addressQrCode = await QRCode.toDataURL(user.wallet.address);
  //       setQrCode(addressQrCode);
  //     } catch (error) {
  //       //console.log(error);
  //     }
  //   }
  //   generateQrCode();
  // }, [user.wallet.address]);

  const handleCopy = () => {
    navigator.clipboard.writeText(user.wallet.address);
  };

  return (
    <Container className="dashboard" fluid={true}>
      <div className="h-screen relative flex flex-col">
        <MobileNavbar back="block" name="Deposit" />
        <div className="deposit_tabs mt-8">
          <button
            className={paymentMethod === 1 ? "active" : ""}
            onClick={() => handlePaymentMethod(1)}
          >
            BTC
          </button>
          <button
            disabled
            className={(paymentMethod === 2 ? "active" : "") + " disabled"}
            onClick={() => handlePaymentMethod(2)}
          >
            Card
          </button>
          <button
            disabled
            className={(paymentMethod === 3 ? "active" : "") + " disabled"}
            onClick={() => handlePaymentMethod(3)}
          >
            Bank
          </button>
        </div>

        <div
          className="bg-white w-full rounded-3xl p-7 flex justify-between items-center"
          style={{
            border: "1px solid rgba(39, 43, 34, 0.05)",
            boxShadow:
              "0px 20px 13px rgba(61, 88, 11, 0.035), 0px 8.14815px 6.51852px rgba(61, 88, 11, 0.0274815), 0px 1.85185px 3.14815px rgba(61, 88, 11, 0.0168519)",
          }}
        >
          <div>
            <p className="font-medium text-3xl mb-1 text-primaryGreen">{`$${(
              balance * price
            ).toFixed(2)}`}</p>
            <p className="font-normal text-sm text-primaryBlack">{`BTC ${balance}`}</p>
          </div>
        </div>

        {!userDeposits ? (
          <p className="text-center mt-20 opacity-50 font-medium text-base">
            No transactions yet...
          </p>
        ) : (
          <div className="mt-7">
            <p className="font-medium text-primaryBlack text-xs">Transactions</p>
            {userDeposits.map((userDeposit) => {
              return (
                <div className="mt-5" key={userDeposit.hash}>
                  <div className="flex">
                    <img src={greenCheck} alt="Deposit Green Check" />
                    <div className="w-full ml-4">
                      <div className="w-full flex justify-between items-center">
                        <p className="font-medium text-primaryBlack text-xs">
                          {userDeposit.address}
                        </p>
                        <p className="font-medium text-primaryBlack text-xs">
                          -${(userDeposit.amount * 0.00000001 * price).toFixed(2)}
                        </p>
                      </div>
                      <div className="flex justify-between items-center mt-1.5">
                        <p className="font-medium text-primaryBlack text-xs opacity-50 capitalize">
                          {userDeposit.date}
                        </p>
                        <p className="font-medium text-primaryBlack text-xs opacity-50">
                          BTC {userDeposit.amount * 0.00000001}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}

        <div className="flex flex-col justify-end h-full">
          <div
            className="flex justify-between items-center p-7 "
            style={{ background: "#FBFBFB", borderTop: "1px  solid #272B22" }}
          >
            <div className="w-full">
              <img src={qrCode} alt="QR Code" style={{ width: "120px" }} />
            </div>
            <div className="flex flex-col w-full">
              <button
                className="text-white py-4 rounded-full mb-2.5"
                style={{
                  background: "linear-gradient(180deg, #272B22 0%, #353930 100%)",
                  height: "55px",
                }}
                onClick={() => handleCopy()}
              >
                Copy
              </button>
              <button
                className="py-4 flex justify-center items-center rounded-full bg-white"
                style={{ border: "2px solid rgba(39, 43, 34, 0.2)", height: "55px" }}
              >
                <img src={share} alt="Share Button" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default DepositMobile;
