import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import SumsubWebSdk from "@sumsub/websdk-react";
import axios from "axios";
import localStorageService from "../../../services/localStorageService";
import { GetBackendURL_V2 } from "../../../../constants";

import "./kyc.scss";

const backendURL = GetBackendURL_V2();

const config = (applicantEmail, applicantPhone) => ({
  lang: "en",
  email: applicantEmail,
  phone: applicantPhone,
  i18n: { document: { subTitles: { IDENTITY: "Upload a document that proves your identity" } } },
  onMessage: (type, payload) => {
    console.log("WebSDK onMessage", type, payload);
  },
  uiConf: {
    customCssStr:
      ":root {\n  --black: #000000;\n   --grey: #F5F5F5;\n  --grey-darker: #B2B2B2;\n  --border-color: #DBDBDB;\n}\n\np {\n  color: var(--black);\n  font-size: 16px;\n  line-height: 24px;\n}\n\nsection {\n  margin: 40px auto;\n}\n\ninput {\n  color: var(--black);\n  font-weight: 600;\n  outline: none;\n}\n\nsection.content {\n  background-color: var(--grey);\n  color: var(--black);\n  padding: 40px 40px 16px;\n  box-shadow: none;\n  border-radius: 6px;\n}\n\nbutton.submit,\nbutton.back {\n  text-transform: capitalize;\n  border-radius: 6px;\n  height: 48px;\n  padding: 0 30px;\n  font-size: 16px;\n  background-image: none !important;\n  transform: none !important;\n  box-shadow: none !important;\n  transition: all 0.2s linear;\n}\n\nbutton.submit {\n  min-width: 132px;\n  background: none;\n  background-color: var(--black);\n}\n\n.round-icon {\n  background-color: var(--black) !important;\n  background-image: none !important;\n}",
  },
  onError: (error) => {
    console.error("WebSDK onError", error);
  },
});

const options = {
  addViewportTag: false,
  adaptIframeHeight: true,
};

const completeHandler = (payload) => {
  console.log("oncomplete");
  console.log(payload);
};
const messageHandler = (payload) => {
  console.log("oncomplete");
  console.log(payload);
};
const errorHandler = (payload) => {
  console.log("oncomplete");
  console.log(payload);
};

const expirationHandler = async () => {
  const token = localStorageService.getItem("token");

  const res = await axios.post(`${backendURL}/sumsubAccesToken`, {
    userToken: token,
  });
  return Promise.resolve(res.data.token);
};

const KYCView = () => {
  const user = useSelector((state) => state.home.home.deposits);

  const [showKYC, setShowKYC] = useState(false);
  const [accessToken, setAccessToken] = useState(null);
  const [isApplicant, setIsApplicant] = useState(false);
  const [applicantData, setApplicantData] = useState(null);

  useEffect(() => {
    const getApplicantData = async () => {
      const token = localStorageService.getItem("token");

      const res = await axios.post(`${backendURL}/applicantData`, {
        userToken: token,
      });

      if (res.data.status === 200) {
        setIsApplicant(true);
        setApplicantData(res.data);
      }
    };

    const getAccessToken = async () => {
      const token = localStorageService.getItem("token");

      const res = await axios.post(`${backendURL}/sumsubAccesToken`, {
        userToken: token,
      });

      setAccessToken(res.data.token);
    };

    getAccessToken();
    getApplicantData();
  }, []);

  if (!accessToken) return null;

  if (showKYC)
    return (
      <SumsubWebSdk
        accessToken={accessToken}
        expirationHandler={expirationHandler}
        config={config(user.user.email, user.phone)}
        options={options}
        onComplete={completeHandler}
        onMessage={messageHandler}
        onError={errorHandler}
      />
    );
  else if (!showKYC && !isApplicant)
    return (
      <div className="kycButtonContainer">
        <Button
          variant="contained"
          color="primary"
          type="submit"
          className="kycBtn init"
          onClick={() => setShowKYC(true)}
        >
          START KYC
        </Button>
      </div>
    );
  else if (!showKYC && applicantData && applicantData.deleted)
    return (
      <div className="kycButtonContainer">
        <Button
          variant="contained"
          color="primary"
          type="submit"
          className="kycBtn failed"
          onClick={() => setShowKYC(true)}
        >
          KYC FAILED
        </Button>
      </div>
    );
  else if (!showKYC && applicantData && !applicantData.review.reviewResult)
    return (
      <div className="kycButtonContainer">
        <Button
          variant="contained"
          color="primary"
          type="submit"
          className="kycBtn success"
          onClick={() => setShowKYC(true)}
        >
          CONTINUE KYC
        </Button>
      </div>
    );
  else if (!showKYC && applicantData && applicantData.review.reviewResult.reviewAnswer === "GREEN")
    return (
      <div className="kycButtonContainer">
        <Button
          variant="contained"
          color="primary"
          type="submit"
          className="kycBtn success"
          onClick={() => setShowKYC(true)}
        >
          KYC VERIFIED
        </Button>
      </div>
    );
  else if (!showKYC && applicantData && applicantData.review.reviewResult.reviewAnswer === "RED") {
    return (
      <div className="kycButtonContainer">
        <Button
          variant="contained"
          color="primary"
          type="submit"
          className="kycBtn failed"
          onClick={() => setShowKYC(true)}
        >
          KYC FAILED
        </Button>
      </div>
    );
  } else return null;
};

export default KYCView;
