import React from "react";
import scrollImg from "../../img/mouse.svg";
import scrollWhiteImg from "../../img/mouse-white.svg";
import heroImg1 from "./img/hero-img1.png";
import heroImg2 from "./img/hero-img2.png";
import heroImg3 from "./img/hero-img3.png";
import heroImg4 from "./img/hero-img4.png";
import { Link } from "react-scroll";

const Hero = () => {
  return (
    <section>
      <div className="hero justify-start ">
        <h1>We're on a mission to make Investing comfortable for everyone.</h1>
        <p>
          Redxam is changing the way the world moves and embraces cryptocurrencies, a gateway to
          more and better business creating a financial solutions platform to make cryptocurrencies
          purchase simple and user-friendly.
        </p>
        <button href="#">
          <Link className="cursor" to="jobs" spy={true} smooth={true}>
            View Job Opening
          </Link>
        </button>
        <Link className="cursor" to="mission" spy={true} smooth={true}>
          <img src={scrollImg} alt="Scroll Down" className="dark:hidden" />
          <img src={scrollWhiteImg} alt="Scroll Down" className="hidden dark:block" />
        </Link>
        <div className="hero_careers_img">
          <img src={heroImg1} alt="" />
          <img src={heroImg2} alt="" />
          <img src={heroImg3} alt="" />
          <img src={heroImg4} alt="" />
        </div>
      </div>
    </section>
  );
};

export default Hero;
