import React from "react";
import Hero from "./Hero";
import Mission from "./Mission";
import Greenbox from "./Greenbox";
import Jobs from "./Jobs";

const Careers = () => {
  return (
    <div className="landing about-landing-content">
      <Hero />
      <div className="container">
        <Mission />
      </div>
      <Greenbox />
      <Jobs />
    </div>
  );
};

export default Careers;
