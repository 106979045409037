import React, { useState } from "react";

const Greenbox = () => {
  const [greenbox, setGreenbox] = useState(null);

  const handleGreenbox = (i) => {
    //console.log(i);
    switch (i) {
      case (i = 0):
        setGreenbox(0);
        break;

      case (i = 1):
        setGreenbox(1);
        break;

      case (i = 2):
        setGreenbox(2);
        break;

      case (i = 3):
        setGreenbox(3);
        break;

      case (i = 4):
        setGreenbox(4);
        break;

      default:
        break;
    }
  };

  return (
    <section id="greenbox">
      <div className="greenbox">
        <div className="container">
          <div className="greenbox_text">
            <h4>Mission And Vision</h4>
            <h2>We call it the Green Box Values</h2>
            <p>
              Our mission is to provide financial freedom so that people can save, send and receive
              money without hassle, even in countries where disposing of your hard-earned money is
              difficult.
            </p>
            <p>
              We also add value with active portfolio management while maximizing our client’s ROI.
              because we understand that ROI is King. Bottom line!... We use the best minds to
              create smart algorithms that maximize your ROI. Together we accomplish your long-term
              financial goals. never available before in such fashion.
            </p>
          </div>
        </div>

        <div className="greenbox_values">
          <div
            className={greenbox === 1 ? "greenbox_values_card active" : "greenbox_values_card"}
            onMouseEnter={() => handleGreenbox(1)}
            onMouseLeave={() => handleGreenbox(0)}
          >
            <p
              className={
                greenbox === 1 ? "greenbox_values_card_number_hover" : "greenbox_values_card_number"
              }
            >
              1
            </p>
            <h3
              className={
                greenbox === 1 ? "greenbox_values_card_title_hover" : "greenbox_values_card_title"
              }
            >
              Customer <br /> Commitment
            </h3>
            <p
              className={
                greenbox === 1
                  ? "greenbox_values_card_description_hover"
                  : "greenbox_values_card_description"
              }
            >
              we develop long lasting relationships that make a positive difference in our
              customers’ lives and their financial statements.
            </p>
          </div>

          <div
            className={greenbox === 2 ? "greenbox_values_card active" : "greenbox_values_card"}
            onMouseEnter={() => handleGreenbox(2)}
            onMouseLeave={() => handleGreenbox(0)}
          >
            <p
              className={
                greenbox === 2 ? "greenbox_values_card_number_hover" : "greenbox_values_card_number"
              }
            >
              2
            </p>
            <h3
              className={
                greenbox === 2 ? "greenbox_values_card_title_hover" : "greenbox_values_card_title"
              }
            >
              Quality
            </h3>
            <p
              className={
                greenbox === 2
                  ? "greenbox_values_card_description_hover"
                  : "greenbox_values_card_description"
              }
            >
              we provide outstanding products and unsurpassed service that, together, deliver
              premium value to our customers.
            </p>
          </div>

          <div
            className={greenbox === 3 ? "greenbox_values_card active" : "greenbox_values_card"}
            onMouseEnter={() => handleGreenbox(3)}
            onMouseLeave={() => handleGreenbox(0)}
          >
            <p
              className={
                greenbox === 3 ? "greenbox_values_card_number_hover" : "greenbox_values_card_number"
              }
            >
              3
            </p>
            <h3
              className={
                greenbox === 3 ? "greenbox_values_card_title_hover" : "greenbox_values_card_title"
              }
            >
              Integrity
            </h3>
            <p
              className={
                greenbox === 3
                  ? "greenbox_values_card_description_hover"
                  : "greenbox_values_card_description"
              }
            >
              we uphold the highest standards of integrity in all of our actions.
            </p>
          </div>

          <div
            className={greenbox === 4 ? "greenbox_values_card active" : "greenbox_values_card"}
            onMouseEnter={() => handleGreenbox(4)}
            onMouseLeave={() => handleGreenbox(0)}
          >
            <p
              className={
                greenbox === 4 ? "greenbox_values_card_number_hover" : "greenbox_values_card_number"
              }
            >
              4
            </p>
            <h3
              className={
                greenbox === 4 ? "greenbox_values_card_title_hover" : "greenbox_values_card_title"
              }
            >
              Team Work
            </h3>
            <p
              className={
                greenbox === 4
                  ? "greenbox_values_card_description_hover"
                  : "greenbox_values_card_description"
              }
            >
              we work together, across boundaries, to meet the needs of our customer and to help the
              company win.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Greenbox;
