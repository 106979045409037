import React, { useState } from "react";
import { Icon, Button, CircularProgress, Snackbar } from "@material-ui/core";
import { Link } from "react-router-dom";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Alert } from "@material-ui/lab";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

const Login = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation("common");

  const [email, setEmail] = useState("");
  const [first, setFirst] = useState(true);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [remember, setRemember] = useState(false);

  const handleSubmit = () => {
    history.push("/home");
  };

  const handleChange = (event) => {
    event.persist();
    setEmail(event.target.value);
  };

  const rememberChange = () => {
    setRemember((oldRemember) => !oldRemember);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="container loginContainer backGreen">
      <div className="contentCenter loginLogo">
        <img src="/assets/images/short_logo.svg" />
      </div>
      <h1 className="text-center title camelcase">{t("login")}</h1>
      <Snackbar
        open={open && !first}
        className="toastDiv"
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity={success ? "success" : "error"}>
          {message}
        </Alert>
      </Snackbar>
      <ValidatorForm onSubmit={handleSubmit} onError={() => null} className="profileForm">
        <TextValidator
          className="emailInput"
          label="Email"
          onChange={handleChange}
          type="email"
          name="email"
          variant="outlined"
          value={email}
          validators={["required", "isEmail"]}
          errorMessages={[t("This field is required."), t("Email is not valid.")]}
        />
        <div className="rememForget">
          <FormControlLabel
            control={
              <Checkbox
                color="default"
                style={{ color: "green" }}
                checked={remember}
                onChange={rememberChange}
                name="remember"
              />
            }
            label={t("rememberMe")}
            className="rememberMe"
          />
        </div>
        <div className="registerBtn">
          <Button variant="contained" color="primary" type="submit" className="loginBtn">
            <Icon>email</Icon>&nbsp;<span className="pl-8 capitalize">Email&nbsp;{t("login")}</span>
          </Button>
        </div>
        <div className="loginTwoBtn">
          <Button variant="text" color="primary" type="submit" className="loginBtn extraLink">
            <Icon>phone</Icon>&nbsp;
            <Link to="/phone" className="registerLink">
              {t("phone")} Login
            </Link>
          </Button>
        </div>
        <div className="loginBtn extraLink">
          <span className="blackText">{t("Don't have an account?")}</span>&nbsp;
          <Link to="/register" className="registerLink camelcase">
            {t("register")}
          </Link>
        </div>
      </ValidatorForm>
      <Button variant="text" color="primary" type="submit" className="loginBtn extraLink">
        <Link to="/home" className="registerLink">
          Home
        </Link>
      </Button>
    </div>
  );
};

export default Login;
