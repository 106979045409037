import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkRole } from "../redux/actions/HomeActions";
import { useHistory } from "react-router";

const Auth = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const home = useSelector((state) => {
    return state.home;
  });

  useEffect(() => {
    dispatch(checkRole(history));
  }, [dispatch, history]);

  return home.loading ? <span>loading..</span> : <>{props.children}</>;
};

export default Auth;
