import { useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import GetBackendURL from "../../../constants";
import axios from "axios";
import localStorageService from "../../services/localStorageService";

export default function CreateUser({ setActiveSection }) {
  const [value, setValue] = useState(0);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    birthPlace: "",
    title: "",
    address: "",
    nearestLandmark: "",
    state: "",
    marriedStatus: "",
    occupation: "",
    idType: "",
    idNumber: "",
    issuance: "",
    issuancePlace: "",
    issuanceStatus: "",
    issuanceDate: "",
    expiringDate: "",
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const requiredFields = [
    "firstName",
    "lastName",
    "email",
    "phone",
    "birthPlace",
    "title",
    "address",
    "nearestLandmark",
    "state",
    "marriedStatus",
    "occupation",
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const backendURL = GetBackendURL();
    const adminToken = localStorageService.getItem("admin_token");

    let emptyFields = [];

    Object.keys(userData).forEach((key) => {
      if (userData[key] === "") {
        emptyFields.push(key);
      }
    });

    if (emptyFields.some((field) => requiredFields.includes(field)))
      return setError(
        `${emptyFields
          .filter((field) => requiredFields.includes(field))
          .join(", ")} field(s) are required!`
      );

    setError("");
    setLoading(true);

    let query = `mutation { createUser(arg: {
      ${Object.keys(userData)
        .filter((key) => userData[key])
        .map(
          (key, idx) =>
            `${key}: "${userData[key]}"${
              idx === Object.keys(userData).filter((key) => userData[key]).length - 1 ? "" : ", "
            }`
        )
        .join("\n")}
     }) { success, message } }`;

    axios
      .post(backendURL + query, null, {
        headers: { authorization: "Bearer " + adminToken },
      })
      .then((res) => {
        if (res.data.data.createUser.success) {
          setActiveSection("users");
        } else {
          setError(res.data.data.createUser.message);
        }
      })
      .catch(() => setError("An error occurred!"))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <form className="flex flex-col" onSubmit={handleSubmit}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="scrollable basic tabs example"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Personal Information" {...a11yProps(0)} />
            <Tab label="Other identities" {...a11yProps(1)} />
            <Tab label="Tax information" {...a11yProps(2)} />
            <Tab label="Documents" {...a11yProps(3)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <div className="flex flex-col">
            <div className="flex-1 flex flex-col md:flex-row">
              <img
                src="https://i.pravatar.cc/48"
                alt="John Doe"
                width="48"
                height="48"
                className="rounded-full ml-4"
              />
              <input
                type="text"
                name="firstname"
                id="firstname"
                placeholder="First name"
                className="flex-1 px-8 py-3 border border-gray-200 rounded-full w-full outline-none focus:shadow focus:border-2 font-extralight mx-2"
                defaultValue={userData.firstName}
                onChange={(e) => setUserData({ ...userData, firstName: e.target.value })}
              />
              <input
                type="text"
                name="lastname"
                id="lastname"
                placeholder="Last name"
                className="flex-1 px-8 py-3 border border-gray-200 rounded-full w-full outline-none focus:shadow focus:border-2 font-extralight mx-2"
                defaultValue={userData.lastName}
                onChange={(e) => setUserData({ ...userData, lastName: e.target.value })}
              />
            </div>
            <hr className="my-4" />
            <div className="flex-1 flex flex-col md:flex-row">
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Email"
                className="flex-1 px-8 py-3 border border-gray-200 rounded-full w-full outline-none focus:shadow focus:border-2 font-extralight mx-2"
                defaultValue={userData.email}
                onChange={(e) => setUserData({ ...userData, email: e.target.value })}
              />
              <input
                type="tel"
                name="phonenumber"
                id="phonenumber"
                placeholder="Phone number"
                className="flex-1 px-8 py-3 border border-gray-200 rounded-full w-full outline-none focus:shadow focus:border-2 font-extralight mx-2"
                defaultValue={userData.phone}
                onChange={(e) => setUserData({ ...userData, phone: e.target.value })}
              />
              <input
                type="text"
                name="placeofbirth"
                id="placeofbirth"
                placeholder="Place of birth"
                className="flex-1 px-8 py-3 border border-gray-200 rounded-full w-full outline-none focus:shadow focus:border-2 font-extralight mx-2"
                defaultValue={userData.birthPlace}
                onChange={(e) => setUserData({ ...userData, birthPlace: e.target.value })}
              />
              <select
                name="title"
                id="title"
                className="flex-1 px-8 py-3 border border-gray-200 rounded-full w-full outline-none focus:shadow focus:border-2 font-extralight mx-2"
                placeholder="Title"
                defaultValue={userData.title}
                onChange={(e) => setUserData({ ...userData, title: e.target.value })}
              >
                <option hidden selected>
                  Title
                </option>
                <option value="Mr">Mr</option>
                <option value="Mrs">Mrs</option>
                <option value="Miss">Miss</option>
                <option value="Ms">Ms</option>
              </select>
            </div>
            <div className="flex-1 flex flex-col md:flex-row mt-4 ">
              <input
                type="text"
                name="address"
                id="address"
                placeholder="Residential Address (Street)"
                className="flex-1 px-8 py-3 border border-gray-200 rounded-full w-full outline-none focus:shadow focus:border-2 font-extralight mx-2"
                defaultValue={userData.address}
                onChange={(e) => setUserData({ ...userData, address: e.target.value })}
              />
              <input
                type="text"
                name="landmark"
                id="landmark"
                placeholder="Nearest landmark"
                className="flex-1 px-8 py-3 border border-gray-200 rounded-full w-full outline-none focus:shadow focus:border-2 font-extralight mx-2"
                defaultValue={userData.nearestLandmark}
                onChange={(e) => setUserData({ ...userData, nearestLandmark: e.target.value })}
              />
            </div>
            <div className="flex-1 flex flex-col md:flex-row mt-4 ">
              <select
                name="state"
                id="state"
                placeholder="State"
                className="flex-1 px-8 py-3 border border-gray-200 rounded-full w-full outline-none focus:shadow focus:border-2 font-extralight mx-2"
                defaultValue={userData.state}
                onChange={(e) => setUserData({ ...userData, state: e.target.value })}
              >
                <option hidden selected>
                  State
                </option>
                <option value="California">California</option>
                <option value="New York">New York</option>
              </select>
              <select
                name="married"
                id="married"
                placeholder="Married status"
                className="flex-1 px-8 py-3 border border-gray-200 rounded-full w-full outline-none focus:shadow focus:border-2 font-extralight mx-2"
                defaultValue={userData.marriedStatus}
                onChange={(e) => setUserData({ ...userData, marriedStatus: e.target.value })}
              >
                <option hidden selected>
                  Married status
                </option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
              <input
                type="text"
                name="occupation"
                id="occupation"
                placeholder="Occupation"
                className="flex-1 px-8 py-3 border border-gray-200 rounded-full w-full outline-none focus:shadow focus:border-2 font-extralight mx-2"
                defaultValue={userData.occupation}
                onChange={(e) => setUserData({ ...userData, occupation: e.target.value })}
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className="flex flex-col">
            <div className="flex-1 flex flex-col md:flex-row">
              <select
                name="typeofid"
                id="typeofid"
                placeholder="Type of ID"
                className="flex-1 px-8 py-3 border border-gray-200 rounded-full w-full outline-none focus:shadow focus:border-2 font-extralight mx-2"
                defaultValue={userData.idType}
                onChange={(e) => setUserData({ ...userData, idType: e.target.value })}
              >
                <option hidden selected>
                  Type of ID
                </option>
                <option value="passport">Passport</option>
              </select>
              <input
                type="number"
                name="idnumber"
                id="idnumber"
                placeholder="ID number"
                className="flex-1 px-8 py-3 border border-gray-200 rounded-full w-full outline-none focus:shadow focus:border-2 font-extralight mx-2"
                defaultValue={userData.idNumber}
                onChange={(e) => setUserData({ ...userData, idNumber: e.target.value })}
              />
            </div>
            <div className="flex-1 flex flex-col md:flex-row mt-4">
              <select
                name="issuance"
                id="issuance"
                placeholder="Issuance"
                className="flex-1 px-8 py-3 border border-gray-200 rounded-full w-full outline-none focus:shadow focus:border-2 font-extralight mx-2"
                defaultValue={userData.issuance}
                onChange={(e) => setUserData({ ...userData, issuance: e.target.value })}
              >
                <option hidden selected>
                  Issuance
                </option>
                <option value="abc">abc</option>
              </select>
              <input
                type="text"
                name="placeofissuance"
                id="placeofissuance"
                placeholder="Place of issuance"
                className="flex-1 px-8 py-3 border border-gray-200 rounded-full w-full outline-none focus:shadow focus:border-2 font-extralight mx-2"
                defaultValue={userData.issuancePlace}
                onChange={(e) => setUserData({ ...userData, issuancePlace: e.target.value })}
              />
              <select
                name="status"
                id="status"
                placeholder="Status"
                className="flex-1 px-8 py-3 border border-gray-200 rounded-full w-full outline-none focus:shadow focus:border-2 font-extralight mx-2"
                defaultValue={userData.issuanceStatus}
                onChange={(e) => setUserData({ ...userData, issuanceStatus: e.target.value })}
              >
                <option hidden selected>
                  Status
                </option>
                <option value="valid">Valid</option>
                <option value="expired">Expired</option>
              </select>
            </div>
            <div className="flex-1 flex flex-col md:flex-row mt-4">
              <div className="flex flex-col flex-1 mx-2">
                <label
                  htmlFor="issuingdate"
                  className="mx-6 text-sm opacity-70 -mb-2.5 bg-white"
                  style={{ width: "fit-content" }}
                >
                  Issuing Date
                </label>
                <input
                  type="date"
                  name="issuingdate"
                  id="issuingdate"
                  placeholder="Issuing Date"
                  className="flex-1 px-8 py-3 border border-gray-200 rounded-full w-full outline-none focus:shadow focus:border-2 font-extralight"
                  defaultValue={userData.issuanceDate}
                  onChange={(e) => setUserData({ ...userData, issuanceDate: e.target.value })}
                />
              </div>
              <div className="flex flex-col flex-1 mx-2">
                <label
                  htmlFor="expiringdate"
                  className="mx-6 text-sm opacity-70 -mb-2.5 bg-white"
                  style={{ width: "fit-content" }}
                >
                  Expiring Date
                </label>
                <input
                  type="date"
                  name="expiringdate"
                  id="expiringdate"
                  placeholder="Expiring Date"
                  className="flex-1 px-8 py-3 border border-gray-200 rounded-full w-full outline-none focus:shadow focus:border-2 font-extralight"
                  defaultValue={userData.expiringDate}
                  onChange={(e) => setUserData({ ...userData, expiringDate: e.target.value })}
                />
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          Item Three
        </TabPanel>
        <TabPanel value={value} index={3}>
          Item Four
        </TabPanel>
        <div className="flex flex-col items-center mt-16">
          <input
            type="submit"
            value="Submit"
            className="text-white rounded-full px-12 py-3 self-center transition-opacity duration-300 hover:opacity-70 disabled:cursor-not-allowed disabled:opacity-30"
            disabled={loading}
            style={{ backgroundColor: "rgba(62,180,2,1)" }}
          />
          {error ? (
            <span className="font-semibold text-center mt-1" style={{ color: "red" }}>
              {error}
            </span>
          ) : null}
        </div>
      </form>
    </>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
