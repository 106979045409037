import {
  MANUAL_USER_CREATE,
  MANUAL_USER_LOADING,
  MANUAL_USER_CLEAR_ERROR,
  MANUAL_USER_ERROR,
} from "../actions/ManualUserActions";

const initialState = {
  loading: false,
  success: false,
  error: false,
};

const manualUserReducer = function (state = initialState, action) {
  switch (action.type) {
    case MANUAL_USER_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case MANUAL_USER_CREATE: {
      return {
        ...state,
        loading: false,
        success: true,
        ...action,
      };
    }
    case MANUAL_USER_CLEAR_ERROR: {
      return {
        ...state,
        success: false,
        error: false,
      };
    }
    case MANUAL_USER_ERROR: {
      return {
        ...state,
        user: null,
        loading: false,
        success: false,
        ...action,
      };
    }
    default:
      return state;
  }
};

export default manualUserReducer;
