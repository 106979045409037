import React from "react";
import listBulletImg from "./img/list-bullet.svg";
import relaxLogoImg from "./img/relax-redxam-logo.png";
import relaxImg from "./img/relax.png";

const Relax = () => {
  return (
    <section id="security">
      <div className="relax mt-24 mb-48">
        <div className="relax_text">
          <h2>
            Relax while your <br />
            Money grows with <br />
            Top security
          </h2>
          <div className="relax_content">
            <ul className="relax_content_list">
              <li className="relax_content_list_item">
                <div className="relax_content_list_item_img">
                  <img src={listBulletImg} alt="" />
                </div>
                <div className="relax_content_list_item_text">
                  <h3>Bank grade security</h3>
                  <p>
                    Our payment processors are compliant to ensure optimum security of your data
                  </p>
                </div>
              </li>

              <li className="relax_content_list_item">
                <div className="relax_content_list_item_img">
                  <img src={listBulletImg} alt="" />
                </div>
                <div className="relax_content_list_item_text">
                  <h3>Best in class investment</h3>
                  <p>
                    We invest in low risk fixed income securities which include treasury bills
                    government bonds, and professionally managed
                  </p>
                </div>
              </li>

              <li className="relax_content_list_item">
                <div className="relax_content_list_item_img">
                  <img src={listBulletImg} alt="" />
                </div>
                <div className="relax_content_list_item_text">
                  <h3>Industry best practices</h3>
                  <p>We supports a variety of the most popular digital currencies</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="relax_img">
          <img src={relaxLogoImg} alt="relax" />
          <img src={relaxImg} alt="redxam Money" />
        </div>
      </div>
    </section>
  );
};

export default Relax;
