/* eslint-disable spellcheck/spell-checker */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "reactstrap";
import { usePlaidLink } from "react-plaid-link";
import { queryDeposits } from "../../../redux/actions/HomeActions";
import QRCode from "qrcode";
import "./_Deposit.sass";

// Components imports
import Navbar from "../../main/Navbar/Navbar";
import MobileNavbar from "../../main/MobileNavbar/MobileNavbar";
import InternalLayout from "../../../../components/layouts/Internal";
import DepositMobile from "./DepositMobile";
import BankDepositController from "./BankDepositController";

// Imgs imports
import share from "./img/share.svg";
import bankIcon from "./img/bank-icon.svg";

import GetBackendURL from "../../../../constants";
import axios from "axios";
import localStorageService from "../../../services/localStorageService";
import { map } from "lodash";

const Deposit2 = () => {
  const dispatch = useDispatch();
  const [paymentMethod, setPaymentMethod] = useState(2);
  const [qrCode, setQrCode] = useState("");

  const [plaidToken, setPlaidToken] = useState("");
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    dispatch(queryDeposits());
  }, [dispatch]);

  const handlePaymentMethod = (i) => {
    switch (i) {
      case (i = 1):
        setPaymentMethod(1);
        break;

      case (i = 2):
        setPaymentMethod(2);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const backendURL = GetBackendURL();
    axios
      .get(`${backendURL.replace("v1?query=", "v2")}/plaid`, {
        headers: { Authorization: "Bearer " + localStorageService.getItem("token") },
      })
      .then((res) => {
        setPlaidToken(res.data.token);
      });

    axios
      .get(`${backendURL.replace("v1?query=", "v2")}/plaid/accounts`, {
        headers: { Authorization: "Bearer " + localStorageService.getItem("token") },
      })
      .then((res) => {
        setAccounts(res.data.accounts);
      });
  }, []);

  return (
    <>
      <Container className="dashboard" fluid={true}>
        <InternalLayout className="deposit_desktop">
          <Navbar title="Deposit" backArrowDisplay="block" potentialDisplay="none" />

          <div className="deposit">
            <div className="deposit_tabs">
              <button
                disabled
                className={(paymentMethod === 1 ? "active" : "") + " disabled"}
                onClick={() => handlePaymentMethod(1)}
              >
                Card
              </button>
              <button
                className={paymentMethod === 2 ? "active" : ""}
                onClick={() => handlePaymentMethod(2)}
              >
                Bank
              </button>
            </div>
            {paymentMethod === 2 && (
              <div>
                <BankDepositController
                  plaid={
                    <PlaidField token={plaidToken} accounts={accounts} setAccounts={setAccounts} />
                  }
                />
              </div>
            )}
          </div>
        </InternalLayout>
      </Container>
    </>
  );
};

function PlaidField({ token, accounts, setAccounts }) {
  const [amount, setAmount] = useState(10);
  const [chosenAccount, setChosenAccount] = useState(null);
  const [loadingDeposit, setLoadingDeposit] = useState(false);

  const userDepositsData = useSelector((state) => state.home.home.deposits);

  let transactions = [];

  if (userDepositsData !== undefined) {
    console.log(userDepositsData);
    userDepositsData.userDeposits.forEach((deposit) => {
      transactions.push(deposit);
    });
  }

  console.log(transactions);

  const { open, exit, ready } = usePlaidLink({
    onSuccess: (public_token, metadata) => {
      const backendURL = GetBackendURL();
      axios
        .post(
          `${backendURL.replace("v1?query=", "v2")}/plaid`,
          {
            public_token,
            metadata,
          },
          {
            headers: { Authorization: "Bearer " + localStorageService.getItem("token") },
          }
        )
        .then(() =>
          axios
            .get(`${backendURL.replace("v1?query=", "v2")}/plaid/accounts`, {
              headers: { Authorization: "Bearer " + localStorageService.getItem("token") },
            })
            .then((res) => {
              setAccounts(res.data.accounts);
            })
        );
    },
    onExit: (err, metadata) => {},
    onEvent: (eventName, metadata) => {},
    token,
    countryCodes: ["US", "CA", "GB", "IE", "FR", "ES", "NL"],
    receivedRedirectUri: null,
    env: "development",
  });

  async function handleDeposit() {
    const backendURL = GetBackendURL();
    // eslint-disable-next-line no-restricted-globals
    let confirmation = confirm(
      `Are you sure you want to deposit $${amount} from ${
        accounts.find((acc) => acc.id === chosenAccount).name
      }?`
    );
    if (!confirmation) return;

    setLoadingDeposit(true);

    axios
      .post(
        `${backendURL.replace("v1?query=", "v2")}/plaid/deposit`,
        {
          account_id: chosenAccount,
          amount,
        },
        {
          headers: { Authorization: "Bearer " + localStorageService.getItem("token") },
        }
      )
      .then(() => {
        alert("Deposit success");
      })
      .catch((error) => alert(error?.response?.data?.message || "An error occurred!"))
      .finally(() => {
        setLoadingDeposit(false);
      });
  }

  return (
    <div className="flex flex-col">
      <button
        onClick={open}
        style={{
          border: "1px solid rgb(62,180,2)",
          backgroundColor: "rgba(62,180,2,1)",
        }}
        disabled={!token.length}
        className="py-3 px-6 rounded-xl transition-opacity duration-300 hover:opacity-70 text-white disabled:opacity-30 disabled:cursor-not-allowed"
      >
        Add bank account
      </button>
      <div className="flex flex-col justify-center items-center space-x-4 mt-4">
        <div className="flex flex-col">
          {accounts.map((account) => (
            <div
              className={`flex flex-row items-center p-4 border border-greenButtons rounded mt-2 transition-opacity duration-300 cursor-pointer hover:opacity-70 ${
                chosenAccount === account.id ? "bg-greenButtons text-white" : ""
              }`}
              onClick={() => setChosenAccount(account.id)}
              key={account.id}
            >
              <img
                src={"data:image/png;base64," + account.logo}
                width="48"
                height="48"
                alt={account.name}
              />
              <div className="flex flex-col ml-2">
                <h3>{account.name}</h3>
              </div>
            </div>
          ))}
        </div>
        {accounts.length > 0 && (
          <div className="flex flex-col mt-10">
            <label className="m-0 text-primaryGreen">Deposit amount: (USD)</label>
            <input
              type="number"
              className="px-3 py-2 border border-greenButtons rounded-md"
              defaultValue={amount}
              onChange={(e) => setAmount(e.target.value)}
              placeholder="deposit amount"
            />
            <button
              onClick={handleDeposit}
              style={{
                border: "1px solid rgb(62,180,2)",
                backgroundColor: "rgba(62,180,2,1)",
              }}
              disabled={!token.length || amount < 3 || !chosenAccount || loadingDeposit}
              className="mt-6 py-3 px-6 rounded-xl transition-opacity duration-300 hover:opacity-70 text-white mt-2 disabled:opacity-30 disabled:cursor-not-allowed"
            >
              Deposit using Stripe
            </button>
          </div>
        )}
      </div>
      {transactions.length > 0 && (
        <div className="mt-10">
          <p className="text-xl mb-4 font-bold">Recent Deposits from Bank</p>

          {transactions.map((transaction) => (
            <div>
              <div className="justify-between items-center text-center border border-greenButtons rounded-md mb-4 text-lg py-2">
                <div>
                  <img
                    src={
                      !transaction.bankIcon
                        ? bankIcon
                        : `data:image/png;base64, ${transaction.bankIcon}`
                    }
                    alt="Bank Logo"
                    className="w-12 mx-auto my-0 mb-1"
                  />
                  <p>
                    <span className="font-bold">Date:</span> {transaction.timestamp}
                  </p>
                  <p>
                    <span className="font-bold">Amount:</span> {transaction.amount}$
                  </p>
                  <p>
                    <span className="font-bold">Bank:</span> {transaction.bankName}
                  </p>
                  <p>
                    <span className="font-bold">Status:</span> {transaction.status}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Deposit2;
