import React from "react";
import Hero from "./Hero";
import InvestingWay from "./InvestingWay";
import Trusted from "./Trusted";
import OurTeam from "./OurTeam";
import WorkWithUs from "./WorkWithUs";
import Newsletter from "../../Newsletter";

const AboutUsContent = () => {
  return (
    <div className="landing about-landing-content">
      <Hero />
      <div className="container">
        <InvestingWay />
        <Trusted />
        <OurTeam />
        <WorkWithUs />
      </div>
      <Newsletter />
    </div>
  );
};

export default AboutUsContent;
