import React from "react";
import Navbar from "../main/Navbar/Navbar";
import Internal from "../../../components/layouts/Internal";

const Withdrawal = () => {
  return (
    <Internal>
      <Navbar title="Withdraw" backArrowDisplay="block" potentialDisplay="none" />
      <div className="mt-32 h-80 w-full flex justify-center items-center">
        <p className="font-bold text-6xl">Coming Soon</p>
      </div>
    </Internal>
  );
};

export default Withdrawal;
