import React, { useState } from "react";
import { useHistory } from "react-router";
import {
  Card,
  InputAdornment,
  CardContent,
  Button,
  Icon,
  CircularProgress,
  Snackbar,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Link } from "react-router-dom";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import MuiPhoneNumber from "material-ui-phone-number";

const Register = () => {
  const history = useHistory();

  const [user, setUser] = useState({
    email: "",
    phone: "",
  });
  const [first, setFirst] = useState(true);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);

  const handleSubmit = () => {
    history.push("/home");
  };

  const handleChange = (event) => {
    event.persist();
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    });
  };

  const handlePhoneBefore = (value) => {
    const phoneNumber = value
      .replaceAll(" ", "")
      .replaceAll("(", "")
      .replaceAll(")", "")
      .replaceAll("-", "");
    setUser({
      ...user,
      phone: phoneNumber,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="container loginContainer">
      <div className="contentCenter loginLogo">
        <img src="/assets/images/short_logo.png" />
      </div>
      <h1 className="text-center title">Register</h1>
      <Snackbar
        open={open && !first}
        className="toastDiv"
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity={success ? "success" : "error"}>
          {message}
        </Alert>
      </Snackbar>
      <ValidatorForm onError={(errors) => null} className="profileForm">
        <TextValidator
          className="emailInput registerEmail"
          label="Email"
          onChange={handleChange}
          type="email"
          name="email"
          variant="outlined"
          value={user.email}
          validators={["required", "isEmail"]}
          errorMessages={["This field is required", "Email is not valid"]}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" className="emailBefore">
                <Icon>email</Icon>
              </InputAdornment>
            ),
          }}
        />
        <MuiPhoneNumber
          className="emailInput phoneInput"
          defaultCountry={"us"}
          onChange={handlePhoneBefore}
          variant="outlined"
          validators={["required"]}
          errormessages={["This field is required"]}
        />
        <div className="registerBtn">
          <Button variant="contained" color="primary" type="submit" className="loginBtn">
            <Icon>person</Icon>&nbsp;
            <span className="pl-8 capitalize">Register</span>
          </Button>
        </div>
        <div className="loginTwoBtn">
          <Button variant="text" color="primary" type="submit" className="loginBtn extraLink">
            <Icon>phone</Icon>&nbsp;
            <Link to="/phone" className="registerLink">
              Phone Login
            </Link>
          </Button>
          <Button variant="text" color="primary" type="submit" className="loginBtn extraLink">
            <Icon>email</Icon>&nbsp;
            <Link to="/login" className="registerLink">
              Email Login
            </Link>
          </Button>
        </div>
      </ValidatorForm>
    </div>
  );
};

export default Register;
