import React from "react";
import { Icon } from "@material-ui/core";
import { Link } from "react-router-dom";
import InternalLayout from "../../../components/layouts/Internal";
import Navbar from "../main/Navbar/Navbar";

const Share = () => {
  return (
    <InternalLayout>
      <Navbar title="FAQ" backArrowDisplay="block" potentialDisplay="none" />
      <div className="shareHeader">
        <div className="faqIcon">
          <Link to="/faq" className="unDecoration">
            <span>FAQ</span>
          </Link>
        </div>
      </div>
      <div className="text-center settingTitle">Share</div>
      <div className="">
        <p className="text-center">Referal bonus</p>
        <p className="text-center">
          Get <span>$400</span> when <br />
          you invite 4 friends!
        </p>
      </div>
      <div className="withdrawBtn cancelBtn contentCenter">
        <Link to="#">Share</Link>
      </div>
    </InternalLayout>
  );
};

export default Share;
