import React from "react";

// Import Components
import Navbar from "./Navbar";
import Hero from "./Hero";
import WhyUs from "./WhyUs";
import Starting from "./Starting";
import Relax from "./Relax";
import Calculate from "./Calculate";
import Banks from "./Banks";
import Plan from "./Plan";
import Question from "./Question";
import Newsletter from "./Newsletter";
import Footer from "./Footer";
import Particles from "react-particles-js";

// Import Styles
import "./landing.sass";

const Landing = () => {
  const particlesSettings = {
    particles: {
      number: {
        value: 12,
      },
      color: {
        value: ["#219653", "#CA7795", "#A0DDFF", "#ACE96B"],
      },
      opacity: {
        value: 0.9,
      },
      size: {
        value: 10,
        random: false,
      },
      line_linked: {
        enable: false,
      },
      move: {
        speed: 0.6,
      },
    },
  };

  return (
    <div className="landing">
      <Navbar />
      <Particles id="particles-js" params={particlesSettings} />
      <div className="container">
        <Hero />
        <WhyUs />
        <Starting />
        <Relax />
        <Calculate />
        <Banks />
        <Plan />
      </div>
      <Question />
      <Newsletter />
      <Footer />
    </div>
  );
};

export default Landing;
