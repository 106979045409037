import React from "react";
import missionImg from "./img/mission-img.png";

const Mission = () => {
  return (
    <section id="mission">
      <div className="mission mt-48 mb-72">
        <div className="mission_img">
          <img src={missionImg} alt="" />
        </div>
        <div className="mission_text">
          <h2>Our Mission</h2>
          <p>
            Redxam is changing the way the world moves and embraces cryptocurrencies, a gateway to
            more and better business creating a financial solutions platform to make
            cryptocurrencies purchase simple and user-friendly.
          </p>
          <p>
            With redxam you have the certainty of being in good hands. Our technological platform
            presents a clear, transparent set of rules because your peace of mind is our peace of
            mind.
          </p>
          <p>
            You can count on an experienced support team that assists you instantly because we want
            you to feel that we are there for you, and to advise you well is our greatest desire.
          </p>
          <p>
            Move your money around the world in a simple and fast way, without complications, always
            looking for a customized solution. Your journey to financial freedom awaits with redxam.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Mission;
