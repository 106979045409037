import axios from "axios";
import localStorageService from "../../services/localStorageService";
import { browserHistory } from "react-router";
import { router } from "react-router-dom";
import GetBackendURL from "../../../constants";

export const USER_LOGIN = "USER_LOGIN";
export const USER_VERIFY = "USER_VERIFY";
export const USER_PHONELOGIN = "USER_PHONELOGIN";
export const USER_PHONEVERIFY = "USER_PHONEVERIFY";
export const USER_REGISTER = "USER_REGISTER";
export const GET_USERS = "GET_USERS";
export const USER_LOADING = "USER_LOADING";
export const USER_CLEAR_ERROR = "USER_CLEAR_ERROR";
export const USER_ERROR = "USER_ERROR";

export function register(user) {
  return (dispatch, getState) => {
    dispatch({ type: USER_LOADING });
    const mutation = `
            mutation {
                createUser(arg: {email: "${user.email}", phone: "${user.phone}"}) {
                    success
                    message
                }
            }
        `;
    const backendURL = GetBackendURL();
    axios
      .post(backendURL + mutation)
      .then((res) => {
        //
        if (res.data.data.createUser.success) {
          dispatch({
            type: USER_REGISTER,
            success: true,
            error: res.data.data.createUser.message,
          });
          setTimeout(() => {
            dispatch({ type: USER_CLEAR_ERROR });
          }, 500);
        } else {
          dispatch({
            type: USER_ERROR,
            success: false,
            error: res.data.data.createUser.message,
          });
          setTimeout(() => {
            dispatch({ type: USER_CLEAR_ERROR });
          }, 500);
        }
      })
      .catch((err) => {
        dispatch({
          type: USER_ERROR,
          success: false,
          error: "Network Error. Please try again later.",
        });
        setTimeout(() => {
          dispatch({ type: USER_CLEAR_ERROR });
        }, 500);
      });
  };
}

export function login(email, history) {
  return async (dispatch, getState) => {
    dispatch({ type: USER_LOADING });
    const mutation = `mutation {
      updateToken(arg:{email: "${email.email}"}) {
        success
        message
        token
      }
    }`;
    const backendURL = GetBackendURL();
    //console.log(backendURL);
    axios
      .post(backendURL + mutation)
      .then((res) => {
        //
        if (res.data.data.updateToken.message === "Successfully logged in!") {
          dispatch({
            type: USER_VERIFY,
            success: true,
            error: res.data.data.updateToken.message,
          });
          localStorageService.setItem("token", res.data.data.updateToken.token);
          history.push("/home");
        }
        if (res.data.data.updateToken.success) {
          dispatch({
            type: USER_VERIFY,
            success: true,
            error: res.data.data.updateToken.message,
          });
          setTimeout(() => {
            dispatch({ type: USER_CLEAR_ERROR });
          }, 500);
        } else {
          dispatch({
            type: USER_ERROR,
            success: false,
            error: res.data.data.updateToken.message,
          });
          setTimeout(() => {
            dispatch({ type: USER_CLEAR_ERROR });
          }, 500);
        }
      })
      .catch((err) => {
        dispatch({
          type: USER_ERROR,
          success: false,
          error: "Network Error. Please try again later.",
        });
        setTimeout(() => {
          dispatch({ type: USER_CLEAR_ERROR });
        }, 500);
      });
  };
}

export function verifyTokenSession(token, history) {
  return (dispatch, getState) => {
    const mutation = `mutation {
      verifyToken(arg: {token: "${token}"}) {
          message
          success
          token
        }
      }`;
    const backendURL = GetBackendURL();
    axios.post(backendURL + mutation).then((res) => {
      if (res.data.data.verifyToken.success) {
        dispatch({
          type: USER_LOGIN,
          success: true,
          error: res.data.data.verifyToken.message,
        });
        localStorageService.setItem("token", res.data.data.verifyToken.token);
        history.push("/home");
      }
    });
  };
}

export function verify(token, history) {
  return (dispatch, getState) => {
    dispatch({ type: USER_LOADING });
    const mutation = `mutation {
            verifyToken(arg: {token: "${token}"}) {
                message
                success
                token
            }
        }`;
    const backendURL = GetBackendURL();
    axios
      .post(backendURL + mutation)
      .then((res) => {
        //console.log(res.data);
        if (res.data.data.verifyToken.success) {
          localStorageService.setItem("token", res.data.data.verifyToken.token);
          dispatch({
            type: USER_LOGIN,
            success: true,
            error: res.data.data.verifyToken.message,
          });
          history.push("/home");
        } else {
          dispatch({
            type: USER_ERROR,
            success: false,
            error: res.data.data.verifyToken.message,
          });
          setTimeout(() => {
            dispatch({ type: USER_CLEAR_ERROR });
            history.push("/login");
          }, 500);
        }
      })
      .catch((err) => {
        dispatch({
          type: USER_ERROR,
          success: false,
          error: "Network Error. Please try again later.",
        });
        setTimeout(() => {
          dispatch({ type: USER_CLEAR_ERROR });
          history.push("/login");
        }, 500);
      });
  };
}

export function phoneLogin(phone) {
  return (dispatch, getState) => {
    dispatch({ type: USER_LOADING });
    const mutation = `mutation {
            updateToken(arg: {phone: "${phone.phone}"}) {
                message
                success
            }
        }`;
    const backendURL = GetBackendURL();
    axios
      .post(backendURL + mutation)
      .then((res) => {
        //
        if (res.data.data.verifyToken.success) {
          dispatch({
            type: USER_PHONELOGIN,
            success: true,
            error: res.data.data.verifyToken.message,
          });
          setTimeout(() => {
            dispatch({ type: USER_CLEAR_ERROR });
            localStorageService.setItem("phoneNumber", phone.phone);
            window.location = "/phone/verify";
          }, 1000);
        } else {
          dispatch({
            type: USER_ERROR,
            success: false,
            error: res.data.data.verifyToken.message,
          });
          setTimeout(() => {
            dispatch({ type: USER_CLEAR_ERROR });
          }, 1000);
        }
      })
      .catch((err) => {
        dispatch({
          type: USER_ERROR,
          success: false,
          error: "Network Error. Please try again later.",
        });
        setTimeout(() => {
          dispatch({ type: USER_CLEAR_ERROR });
        }, 500);
      });
  };
}

export function phoneVerify(data) {
  return (dispatch, getState) => {
    dispatch({ type: USER_LOADING });
    const mutation = `
            mutation {
                verifyToken(arg: {phone: "${data.phone}", code: "${data.code}"}) {
                    message
                    success
                }
            }
        `;
    const backendURL = GetBackendURL();
    axios
      .post(backendURL + mutation)
      .then((res) => {
        //
        if (res.data.data.verifyToken.success) {
          dispatch({
            type: USER_PHONELOGIN,
            success: true,
            error: res.data.data.verifyToken.message,
          });
          setTimeout(() => {
            dispatch({ type: USER_CLEAR_ERROR });
            localStorageService.setItem("token", res.data.data.verifyToken.token);
            localStorageService.removeItem("phoneNumber");
            window.location = "/home";
          }, 4000);
        } else {
          dispatch({
            type: USER_ERROR,
            success: false,
            error: res.data.data.verifyToken.message,
          });
          setTimeout(() => {
            dispatch({ type: USER_CLEAR_ERROR });
            localStorageService.removeItem("phoneNumber");
          }, 4000);
        }
      })
      .catch((err) => {
        dispatch({
          type: USER_ERROR,
          success: false,
          error: "Network Error. Please try again later.",
        });
        setTimeout(() => {
          dispatch({ type: USER_CLEAR_ERROR });
          localStorageService.removeItem("phoneNumber");
        }, 4000);
      });
  };
}

export function getUsers() {
  return (dispatch, getState) => {
    dispatch({ type: USER_LOADING });
    const query = `
            query {
                users{
                    _id
                    email
                }
            }
        `;
    const backendURL = GetBackendURL();
    axios
      .post(backendURL + query)
      .then((res) => {
        //
        dispatch({
          type: GET_USERS,
          users: res.data.data.users,
        });
      })
      .catch((err) => {
        dispatch({
          type: USER_ERROR,
          success: false,
          error: "Something went wrong. Please try again.",
        });
        setTimeout(() => {
          dispatch({ type: USER_CLEAR_ERROR });
        }, 4000);
      });
  };
}
