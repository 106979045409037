import React, { useState, useEffect } from "react";
import Compound from "../../debug/compound.lib";

import redxamLogo from "./img/redxam-logo.svg";
import bankChase from "./img/bank-chase.png";
import bankBofa from "./img/bank-bofa2.png";
import bankTD from "./img/bank-td.png";

const Banks = () => {
  const [averageRedxam, setAverageRedxam] = useState(5);

  useEffect(() => {
    (async () => {
      try {
        const average = await Compound.getBalancedAverage30Day();
        setAverageRedxam(average.balancedAverage.toFixed(2));
      } catch {
        setAverageRedxam(5);
      }
    })();
  }, []);

  return (
    <section>
      <div className="banks">
        <h2>This is what other banks around the world will give you.</h2>
        <div className="banks_list">
          <div className="banks_list_item">
            <div className="banks_list_item_img">
              <img src={redxamLogo} alt="" />
            </div>
            <div className="banks_list_item_info">
              <p className="banks_list_item_info_name">redxam</p>
              <p className="banks_list_item_info_percentaje">{averageRedxam}%</p>
            </div>
          </div>

          <div className="banks_list_item">
            <div className="banks_list_item_img">
              <img src={bankChase} alt="" />
            </div>
            <div className="banks_list_item_info">
              <p className="banks_list_item_info_name">Chase</p>
              <p className="banks_list_item_info_percentaje">0.01%</p>
            </div>
          </div>

          <div className="banks_list_item">
            <div className="banks_list_item_img">
              <img src={bankBofa} alt="" />
            </div>
            <div className="banks_list_item_info">
              <p className="banks_list_item_info_name">Bofa</p>
              <p className="banks_list_item_info_percentaje">0.01%</p>
            </div>
          </div>

          <div className="banks_list_item">
            <div className="banks_list_item_img">
              <img src={bankTD} alt="" />
            </div>
            <div className="banks_list_item_info">
              <p className="banks_list_item_info_name">TD Bank</p>
              <p className="banks_list_item_info_percentaje">0.01%</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banks;
