import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Chart from "react-apexcharts";

export default function PerformanceChart() {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.home.user);
  if (!user) return null;

  let balance = [];
  let timestamp = [];

  if (user) {
    user.performanceRecords.userPerformanceRecords.forEach((record) => {
      balance.push(record.balance.toFixed(1));
      timestamp.push(record.timestamp);
    });
  }

  const chartSeries = [{ name: "series1", data: balance }];

  const chartOptions = {
    chart: {
      width: "100%",
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    xaxis: {
      type: "numeric",
      categories: timestamp,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    colors: ["#fff"],
    grid: { show: false },
  };

  return (
    <div className="perfomance_chart" style={{ margin: "0 auto" }}>
      <div className="perfomance_chart_title">
        <h4>Account value</h4>
      </div>
      <div className="perfomance_chart_money">
        <h2>{`$${user.balance.toFixed(5)}`}</h2>
      </div>
      <div className="perfomance_chart_earnings">
        <p className="text-center text-base text-primaryBlack">{`+ $${user.performanceRecords.performanceRecordDolarChange.toFixed(
          5
        )} (+${user.performanceRecords.performanceRecordPercentChange.toFixed(5)}%)`}</p>
      </div>
      <Chart options={chartOptions} series={chartSeries} type="line" width="820" height="150" />
      <div className="perfomance_chart_controls">
        <button onClick={() => console.log("DAY")}>1D</button>
        <button onClick={() => console.log("MONTH")}>1M</button>
        <button onClick={() => console.log("MONTH3")}>3M</button>
        <button onClick={() => console.log("MONTH6")}>6M</button>
        <button onClick={() => console.log("YEAR")}>1Y</button>
        <button onClick={() => console.log("ALL")}>All</button>
      </div>
    </div>
  );
}
