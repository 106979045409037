import { useEffect, useState } from "react";
import GetBackendURL from "../../../constants";
import axios from "axios";
import localStorageService from "../../services/localStorageService";

export default function Users() {
  let [users, setUsers] = useState([]);

  useEffect(() => {
    const query = `
      query FetchUsers {
        users {
          ... {
            _id
            phone
            email
            firstName
            lastName
          }
        }
      }
    `;
    const backendURL = GetBackendURL();
    axios
      .post(backendURL + query, null, {
        headers: { authorization: "Bearer " + localStorageService.getItem("token") },
      })
      .then((res) => {
        setUsers(res.data.data.users);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return users.length ? (
    <>
      <table className="w-full border-separate">
        <thead>
          <tr>
            <th className="bg-black bg-opacity-10 p-4 text-left border border-black border-opacity-20 rounded-tl-xl">
              Pic:
            </th>
            <th className="bg-black bg-opacity-10 p-4 text-left border border-black border-opacity-20">
              Name:
            </th>
            <th className="bg-black bg-opacity-10 p-4 text-left border border-black border-opacity-20">
              Phone:
            </th>
            <th className="bg-black bg-opacity-10 p-4 text-left border border-black border-opacity-20">
              Tax No:
            </th>
            <th className="bg-black bg-opacity-10 p-4 text-left border border-black border-opacity-20">
              Status:
            </th>
            <th className="bg-black bg-opacity-10 p-4 text-left border border-black border-opacity-20">
              Reg Date:
            </th>
            <th className="bg-black bg-opacity-10 p-4 text-left border border-black border-opacity-20 rounded-tr-xl">
              Last Updated:
            </th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user._id}>
              <td className="bg-black bg-opacity-5 p-4 text-left border border-black border-opacity-20">
                <img
                  src={"https://i.pravatar.cc/48"}
                  alt={user.firstName + " " + user.lastName}
                  className="rounded-full"
                  width="48"
                  height="48"
                />
              </td>
              <td className="bg-black bg-opacity-5 p-4 text-left border border-black border-opacity-20">
                {!user.firstName && !user.lastName ? (
                  <span className="underline font-bold">N/A</span>
                ) : (
                  (user.firstName || "") + " " + (user.lastName || "")
                )}
              </td>
              <td className="bg-black bg-opacity-5 p-4 text-left border border-black border-opacity-20">
                {user.phone || <span className="underline font-bold">N/A</span>}
              </td>
              <td className="bg-black bg-opacity-5 p-4 text-left border border-black border-opacity-20">
                {"1234567890"}
                {/* placeholder value */}
              </td>
              <td
                className="bg-black bg-opacity-5 p-4 text-left border border-black border-opacity-20"
                style={{ color: user.accountStatus === "active" ? "green" : "red" }}
              >
                {user.accountStatus || <span className="underline font-bold">N/A</span>}
              </td>
              <td className="bg-black bg-opacity-5 p-4 text-left border border-black border-opacity-20">
                {"20 Nov 1898"} {/* placeholder value */}
              </td>
              <td className="bg-black bg-opacity-5 p-4 text-left border border-black border-opacity-20">
                {"14 / Dec / 1990"} {/* placeholder value */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  ) : (
    <h1 className="text-2xl mx-32">Loading..</h1>
  );
}
