import React, { useState, useEffect, useMemo } from "react";
import IOSSlider from "../../../../components/Slider";
import Compound from "../../debug/compound.lib";
import AnimatedNumber from "animated-number-react";
import banks from "./img/regular-banks.svg";
import conserv from "./img/conservative-plan.svg";

const marks = [
  {
    value: 0,
  },
  {
    value: 10,
  },
  {
    value: 25,
  },
  {
    value: 50,
  },
  {
    value: 100,
  },
];

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const Calculate = () => {
  const [averageRedxam, setAverageRedxam] = useState(0.05);
  const rates = useMemo(
    () => [
      {
        id: 1,
        name: "Passive plan",
        interest: averageRedxam,
        iconUrl: conserv,
      },

      {
        id: 2,
        name: "Regular banks",
        interest: 0.0001,
        iconUrl: banks,
      },
    ],
    [averageRedxam]
  );
  useEffect(() => {
    (async () => {
      try {
        const average = await Compound.getBalancedAverage30Day();
        setAverageRedxam(average.balancedAverage.toFixed(2) / 100);
        setInterestRate(rates[0]);
      } catch {
        setAverageRedxam(5);
        setInterestRate(rates[0]);
      }
    })();
  }, [rates]);

  const [activeAsset, setActiveAsset] = useState(1);

  const [interestRate, setInterestRate] = useState(rates[0]);
  const [value, setValue] = useState(3000);
  const [total, setTotal] = useState(3000 * interestRate.interest);

  const handleValueChange = (_, value) => {
    let val = value;
    if (value === 0) {
      val = 500;
    } else if (value <= 49) {
      val = value * 200;
    } else if (value >= 50) {
      val = 10000 + 1800 * (value - 50);
    }
    setValue(val);
    setTotal(val * interestRate.interest);
  };

  const handleManualNumber = (val) => {
    setValue(val);
    setTotal(val * interestRate.interest);
  };

  const handleAsset = (rate) => {
    setInterestRate(rate);
    setTotal(value * rate.interest);
    if (rate.id === 1) {
      setActiveAsset(rate.id);
    } else {
      setActiveAsset(rate.id);
    }
  };

  return (
    <section>
      <div className="calculate">
        <h2>Let's calculate how much you'd earn</h2>
        <div className="calculator">
          <div className="assets">
            <h4>Choose Plan</h4>
            <div className="assets_options">
              {rates.map((rate) => (
                <div key={rate.name} className="assets_options_item">
                  <input
                    type="button"
                    id="ETH"
                    onClick={() => handleAsset(rate)}
                    style={{
                      background: `url(${rate.iconUrl}) no-repeat center`,
                    }}
                    className={activeAsset === rate.id ? "active" : ""}
                  />
                  <label htmlFor="ETH">{rate.name}</label>
                </div>
              ))}
            </div>
          </div>
          <div className="amount">
            <h4>Select Amount</h4>
            <input
              className="money"
              value={`$${numberWithCommas(value)}`}
              onChange={({ target }) => {
                const value = target.value.replace(/[^0-9]/g, "");
                handleManualNumber(value);
              }}
            />
            <IOSSlider
              aria-label="ios slider"
              defaultValue={10}
              step={5}
              marks={marks}
              valueLabelDisplay="off"
              onChange={handleValueChange}
            />
            <p className="capital">
              This is your capital that’ll be invested <br />
              with us.
            </p>
          </div>
        </div>
        <div className="result">
          <h4>You get paid</h4>
          <p>
            <span>{`$${Number(total).toFixed(2)}`}</span>
            /year
          </p>
        </div>
      </div>
    </section>
  );
};

export default Calculate;
