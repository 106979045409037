export const CHANGE_BACKEND = "CHANGE_BACKEND";

export function changeBackend(backend) {
  return (dispatch) => {
    dispatch({
      type: CHANGE_BACKEND,
      payload: backend,
    });
  };
}
