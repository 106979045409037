/* eslint-disable spellcheck/spell-checker */
import React from "react";
import scrollImg from "./img/mouse.svg";
import scrollWhiteImg from "./img/mouse-white.svg";
import heroImg from "./img/hero.png";
import bigHeroImg from "./img/hero.svg";
import check from "./img/check.svg";
import { Button, Header, Form, Modal } from "semantic-ui-react";
import axios from "axios";
import GetBackendURL from "../../../../constants";

import { Link } from "react-scroll";
import WaitlistModal from "./WaitlistModal";
import "./successicon.css";

const Hero = () => {
  return (
    <section>
      <div className="hero dark:bg-gray-800 mt-40 mb-72">
        <h1>
          Your Personal Crypto Investment Assistant. <span>Worry-Free</span> Crypto Holdings.
        </h1>
        <p>
          redxam is changing the way the world moves and embraces cryptocurrencies, a gateway to
          more and better business creating a financial solutions platform to make cryptocurrency
          purchases simple and user-friendly.
        </p>

        <WaitlistModal />

        <Link className="cursor" to="benefits" spy={true} smooth={true}>
          <img src={scrollImg} alt="Scroll Down" className="dark:hidden" />
          <img src={scrollWhiteImg} alt="Scroll Down" className="hidden dark:block" />
        </Link>
        <img src={bigHeroImg} alt="redxam Mobile App" className="hero_image hidden lg:block" />
        <img src={heroImg} alt="redxam Mobile App" className="hero_image lg:hidden" />
      </div>
    </section>
  );
};

export default Hero;
