import React, { useState } from "react";
import { isBrowser, isMobile } from "react-device-detect";

// Components imports
import { Container } from "reactstrap";
import MobileNavbar from "../../main/MobileNavbar/MobileNavbar";

// Imgs Imports
import pasteButton from "./img/paste-button.svg";

const DepositSend = () => {
  const [searchBar, setSearchBar] = useState(false);
  return (
    <Container className="dashboard" fluid={true}>
      <div className="h-screen relative flex flex-col">
        <MobileNavbar cancel="block" name="Send to" />

        <input
          type="text"
          placeholder="Search recipient"
          className="mt-7 mb-20 outline-none"
          onChange={() => setSearchBar(true)}
        />

        {searchBar === false ? (
          <div className="text-center">
            <p>No recents</p>
            <p>Sent addresses will be shown here</p>
          </div>
        ) : (
          <>
            <div
              className="py-5 px-7 flex items-center justify-between"
              style={{
                border: "1px solid rgba(39, 43, 34, 0.05)",
                boxShadow:
                  "0px 20px 13px rgba(61, 88, 11, 0.035), 0px 8.14815px 6.51852px rgba(61, 88, 11, 0.0274815), 0px 1.85185px 3.14815px rgba(61, 88, 11, 0.0168519)",
              }}
            >
              <div>
                <p className="mb-2.5 opacity-50 text-primaryBlack text-xs font-medium">
                  Paste from clipboard
                </p>
                <p className="text-primaryBlack font-medium text-sm">
                  0asd54a654sda1sd231a0sa <br /> 545400
                </p>
              </div>
              <img src={pasteButton} alt="Paste Button" />
            </div>

            <div className="flex justify-center w-full h-full mb-14">
              <button
                className="self-end w-full text-white py-5 rounded-full"
                style={{
                  background: "linear-gradient(180deg, #272B22 0%, #353930 100%)",
                  boxShadow:
                    "0px 20px 13px rgba(39, 43, 34, 0.1), 0px 8.14815px 6.51852px rgba(39, 43, 34, 0.05), 0px 1.85185px 3.14815px rgba(39, 43, 34, 0.025)",
                }}
              >
                Next
              </button>
            </div>
          </>
        )}
      </div>
    </Container>
  );
};

export default DepositSend;
