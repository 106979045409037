import React, { useState } from "react";
import { Icon, Grid, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Chart } from "chart.js";
import { Line } from "@reactchartjs/react-chart.js";
import { useHistory } from "react-router";

const Potential = () => {
  const history = useHistory();
  const [marked, setMarked] = useState(false);

  const data = {
    labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    datasets: [
      {
        label: "# of Votes",
        data: [12, 19, 3, 5, 2, 3, 50],
        fill: false,
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "rgba(255, 99, 132, 0.2)",
      },
    ],
  };

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  const markHandler = () => {
    setMarked((old) => {
      return !old;
    });
  };

  return (
    <div className="potential">
      <div className="backgroundPotential"></div>
      <div className="depoWithPotential">
        <div className="backIcon">
          <Link to="/home" className="unDecoration">
            <Icon>arrow_back</Icon>
          </Link>
        </div>
        <div className="faqIcon">
          <Link to="#" className="unDecoration">
            <Icon>more_horiz</Icon>
          </Link>
        </div>
      </div>
      <h1 className="text-center settingTitle">Your Potential</h1>
      <Grid container spacing={0} alignItems="center" className="depoWithPotential">
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <div className="depositPotential">
            <Icon>fiber_manual_record</Icon>
            <p>Deposit: $15,050.55</p>
          </div>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <div className="withdrawPotential">
            <Icon>fiber_manual_record</Icon>
            <p>Withdraw: $5,055</p>
          </div>
        </Grid>
      </Grid>
      <h3 className="text-center">Hypothetical at same rate:</h3>
      <div className="contentCenter">
        <Button className="perYear" onClick={markHandler}>
          {!marked && (
            <React.Fragment>
              $1500/Year&nbsp;&nbsp;&nbsp;&nbsp;<Icon>equalizer</Icon>
            </React.Fragment>
          )}
          {marked && <Icon>done</Icon>}
        </Button>
      </div>
      {marked && (
        <div className="rouletteGraph">
          <p>$1500/Year</p>
          <div className="roulette"></div>
        </div>
      )}
      <Line data={data} options={options} />
    </div>
  );
};

export default Potential;
