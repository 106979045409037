import React, { useState } from "react";
import questionsImg from "./img/questions-img.png";
import downArrowImg from "./img/question-downarrow.svg";

const Question = () => {
  const [question, setQuestion] = useState(0);

  const handleQuestion = (i) => {
    if (i === question) setQuestion(0);
    else setQuestion(i);
  };

  let FAQ = [
    {
      question: "example",
      answer: "example",
      image: questionsImg,
    },
  ];

  return (
    <section id="faq">
      <div className="question">
        <div className="container">
          <h4>Frequently asked questions</h4>
          <h2>
            Quick answer to <br />
            your questions.
          </h2>
        </div>
        <img className="question_img" src={questionsImg} alt="" />
        <div className="container">
          <div className="faq">
            <div className="faq_list">
              <div className="faq_list_item" id="question1">
                <div className="faq_list_item_link" onClick={() => handleQuestion(1)}>
                  <p>How does redxam earn money?</p>
                  <img
                    src={downArrowImg}
                    alt=""
                    className={question === 1 ? "downarrow active" : "downarrow"}
                  />
                </div>
                <div
                  className={
                    question === 1 ? "faq_list_item_answer active" : "faq_list_item_answer"
                  }
                >
                  <p style={{ color: "#000000" }}>
                    redxam makes a quarter of the interest earned. Instead of having a fix pricing,
                    we strive to provide the best returns for our customers.
                  </p>
                </div>
              </div>

              <div className="faq_list_item" id="question2">
                <div className="faq_list_item_link" onClick={() => handleQuestion(2)}>
                  <p>How do the portfolios generate interest lending assets?</p>
                  <img
                    src={downArrowImg}
                    alt=""
                    className={question === 2 ? "downarrow active" : "downarrow"}
                  />
                </div>
                <div
                  className={
                    question === 2 ? "faq_list_item_answer active" : "faq_list_item_answer"
                  }
                >
                  <p style={{ color: "#000000" }}>
                    Our portfolios, including our conservative plan, lend the money through a secure
                    collateral-based system to borrowers that pay an interest for the assets
                    borrowed.
                  </p>
                </div>
              </div>

              <div className="faq_list_item" id="question3">
                <div className="faq_list_item_link" onClick={() => handleQuestion(3)}>
                  <p>How secure is redxam?</p>
                  <img
                    src={downArrowImg}
                    alt=""
                    className={question === 3 ? "downarrow active" : "downarrow"}
                  />
                </div>
                <div
                  className={
                    question === 3 ? "faq_list_item_answer active" : "faq_list_item_answer"
                  }
                >
                  <p style={{ color: "#000000" }}>
                    We use bank-level security combined with impenetrable Bitcoin and Ethereum
                    blockchain technologies. this combination provides one of the most secured
                    financial networks in the world.
                  </p>
                </div>
              </div>

              <div className="faq_list_item" id="question4">
                <div className="faq_list_item_link" onClick={() => handleQuestion(4)}>
                  <p>Does redxam require verified identification?</p>
                  <img
                    src={downArrowImg}
                    alt=""
                    className={question === 4 ? "downarrow active" : "downarrow"}
                  />
                </div>
                <div
                  className={
                    question === 4 ? "faq_list_item_answer active" : "faq_list_item_answer"
                  }
                >
                  <p style={{ color: "#000000" }}>
                    We don’t require verification as long as you use crypto currencies for your
                    savings account. Stable-coins are the recommended way of depositing assets.
                  </p>
                </div>
              </div>

              <div className="faq_list_item" id="question5">
                <div className="faq_list_item_link" onClick={() => handleQuestion(5)}>
                  <p>How much do you need to invest with redxam?</p>
                  <img
                    src={downArrowImg}
                    alt=""
                    className={question === 5 ? "downarrow active" : "downarrow"}
                  />
                </div>
                <div
                  className={
                    question === 5 ? "faq_list_item_answer active" : "faq_list_item_answer"
                  }
                >
                  <p style={{ color: "#000000" }}>
                    We recommend depositing at least the value of 0.1BTC as this will function as a
                    cushion for any market volatility and transfer fees. We estimate about $20 to
                    $30 on fees so we bundle transactions with other redxam users to reduce the
                    costs to virtually $0.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Question;
