const cUSDT = "0xf650C3d88D12dB855b8bf7D11Be6C55A4e07dCC9";
const cSAI = "0xf5dce57282a584d2746faf1593d3121fcac444dc";
const cDAI = "0x5d3a536e4d6dbd6114cc1ead35777bab948e3643";
const cUSDC = "0x39aa39c021dfbae8fac545936693ac917d5e7563";
// const fetch = require("node-fetch");

// export const getLast30Days = async () => {
//   const json = await fetch(
//     "https://api.compound.finance/api/v2/market_history/graph?asset=0xf5dce57282a584d2746faf1593d3121fcac444dc&min_block_timestamp=1556747900&max_block_timestamp=1559339900&num_buckets=10"
//   ).then((response) => response.json());
//   //console.log("json", json);
//   return json;
// };
const getTime = () => {
  const secondsSinceEpoch = Math.round(Date.now() / 1000);
  const seconds3MonthsAgo = secondsSinceEpoch - 3 * 30 * 24 * 3600;
  const seconds30DaysAgo = secondsSinceEpoch - 30 * 24 * 3600;
  const seconds7DaysAgo = secondsSinceEpoch - 7 * 24 * 3600;

  return { secondsSinceEpoch, seconds3MonthsAgo, seconds7DaysAgo, seconds30DaysAgo };
};

const getLast30Days = async (coin) => {
  const time = getTime();
  const rates = await fetch(
    `https://api.compound.finance/api/v2/market_history/graph?` +
      `asset=${coin}` +
      `&min_block_timestamp=${time.seconds30DaysAgo}` +
      `&max_block_timestamp=${time.secondsSinceEpoch}` +
      // eslint-disable-next-line spellcheck/spell-checker
      `&num_buckets=360`
  )
    .then((response) => response.json())
    .then((json) => json.supply_rates)
    .catch((err) => console.error(err));
  // //console.log("rates", rates);
  return rates;
};

const getLast30DaysSimple = async (coin) => {
  const time = getTime();
  const rates = await fetch(
    `https://api.compound.finance/api/v2/market_history/graph?` +
      `asset=${coin}` +
      `&min_block_timestamp=${time.seconds30DaysAgo}` +
      `&max_block_timestamp=${time.secondsSinceEpoch}` +
      // eslint-disable-next-line spellcheck/spell-checker
      `&num_buckets=120`
  )
    .then((response) => response.json())
    .then((json) => json.supply_rates)
    .catch((err) => console.error(err));
  // //console.log("rates", rates);
  return rates;
};

const getLast7Days = async (coin) => {
  const time = getTime();
  //console.log(time);
  const rates = await fetch(
    `https://api.compound.finance/api/v2/market_history/graph?` +
      `asset=${coin}` +
      `&min_block_timestamp=${time.seconds7DaysAgo}` +
      `&max_block_timestamp=${time.secondsSinceEpoch}` +
      // eslint-disable-next-line spellcheck/spell-checker
      `&num_buckets=50`
  )
    .then((response) => response.json())
    .then((json) => json.supply_rates)
    .catch((err) => console.error(err));
  // //console.log("rates", rates);
  return rates;
};

const formatData = (supplyRates) =>
  supplyRates.map((block) => [block.block_timestamp * 1000, Number((block.rate * 100).toFixed(2))]);

const getBalancedAverage30Day = async () => {
  const dai = await getLast30DaysSimple(cDAI);
  const usdc = await getLast30DaysSimple(cUSDC);
  const usdt = await getLast30DaysSimple(cUSDT);
  let daiRates = formatData(dai);
  let usdcRates = formatData(usdc);
  let usdtRates = formatData(usdt);

  // //console.log(compoundMixRates);
  let averageUSDT = usdtRates.map((v) => v[1]).reduce((a, b) => a + b, 0) / 120;
  let averageUSDC = usdcRates.map((v) => v[1]).reduce((a, b) => a + b, 0) / 120;
  let averageDAI = daiRates.map((v) => v[1]).reduce((a, b) => a + b, 0) / 120;
  let average = 0 + averageUSDT * 0.5 + averageUSDC * 0.25 + averageDAI * 0.25;
  const rtnObj = {
    balancedAverage: average,
    averageUSDT,
    averageUSDC,
    averageDAI,
  };
  return rtnObj;
};

getBalancedAverage30Day().catch(() => undefined);

const Compound = {
  getBalancedAverage30Day,
  getLast30Days,
  getLast30DaysSimple,
  getLast7Days,
  cUSDC,
  cUSDT,
  cDAI,
  cSAI,
};
export default Compound;
