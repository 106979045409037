import React, { useState } from "react";
import { isBrowser, isMobile } from "react-device-detect";

// Components imports
import { Container } from "reactstrap";
import MobileNavbar from "../../main/MobileNavbar/MobileNavbar";

// Imgs Imports
import switchButton from "./img/switch-button.svg";
import eraseButton from "./img/erase-button.svg";

const DepositAmount = () => {
  const [amount, setAmount] = useState("0");
  return (
    <Container className="dashboard" fluid={true}>
      <div className="h-screen relative flex flex-col">
        <MobileNavbar cancel="block" />

        <div className="flex items-center justify-between mt-7">
          <button
            className="text-white rounded-full px-3 py-4 text-base font-semibold"
            style={{
              width: "55px",
              height: "55px",
              background: "linear-gradient(180deg, #272B22 0%, #353930 100%)",
              boxShadow:
                "0px 20px 13px rgba(39, 43, 34, 0.1), 0px 8.14815px 6.51852px rgba(39, 43, 34, 0.05), 0px 1.85185px 3.14815px rgba(39, 43, 34, 0.025)",
            }}
          >
            Max
          </button>
          <div className="text-center">
            <p className="text-primaryGreen font-medium text-2xl mb-2.5">{`$${amount}`}</p>
            <p className="opacity-50 font-normal text-sm text-primaryBlack">BTC 0,0090 </p>
          </div>
          <button
            className="rounded-full px-3 py-4 flex justify-center items-center"
            style={{
              width: "55px",
              height: "55px",
              background: "linear-gradient(180deg, #272B22 0%, #353930 100%)",
              boxShadow:
                "0px 20px 13px rgba(39, 43, 34, 0.1), 0px 8.14815px 6.51852px rgba(39, 43, 34, 0.05), 0px 1.85185px 3.14815px rgba(39, 43, 34, 0.025)",
            }}
          >
            <img src={switchButton} alt="Switch Button" />
          </button>
        </div>

        <div className="grid grid-cols-3 mx-auto gap-10 mt-16">
          <button
            className="rounded-full"
            style={{ width: "55px", height: "55px", border: "2px solid rgba(39, 43, 34, 0.2)" }}
            onClick={() => setAmount(amount + "1")}
          >
            1
          </button>
          <button
            className="rounded-full"
            style={{ width: "55px", height: "55px", border: "2px solid rgba(39, 43, 34, 0.2)" }}
            onClick={() => setAmount(amount + "2")}
          >
            2
          </button>
          <button
            className="rounded-full"
            style={{ width: "55px", height: "55px", border: "2px solid rgba(39, 43, 34, 0.2)" }}
            onClick={() => setAmount(amount + "3")}
          >
            3
          </button>
          <button
            className="rounded-full"
            style={{ width: "55px", height: "55px", border: "2px solid rgba(39, 43, 34, 0.2)" }}
            onClick={() => setAmount(amount + "4")}
          >
            4
          </button>
          <button
            className="rounded-full"
            style={{ width: "55px", height: "55px", border: "2px solid rgba(39, 43, 34, 0.2)" }}
            onClick={() => setAmount(amount + "5")}
          >
            5
          </button>
          <button
            className="rounded-full"
            style={{ width: "55px", height: "55px", border: "2px solid rgba(39, 43, 34, 0.2)" }}
            onClick={() => setAmount(amount + "6")}
          >
            6
          </button>
          <button
            className="rounded-full"
            style={{ width: "55px", height: "55px", border: "2px solid rgba(39, 43, 34, 0.2)" }}
            onClick={() => setAmount(amount + "7")}
          >
            7
          </button>
          <button
            className="rounded-full"
            style={{ width: "55px", height: "55px", border: "2px solid rgba(39, 43, 34, 0.2)" }}
            onClick={() => setAmount(amount + "8")}
          >
            8
          </button>
          <button
            className="rounded-full"
            style={{ width: "55px", height: "55px", border: "2px solid rgba(39, 43, 34, 0.2)" }}
            onClick={() => setAmount(amount + "9")}
          >
            9
          </button>
          <button
            className="rounded-full"
            style={{ width: "55px", height: "55px", border: "2px solid rgba(39, 43, 34, 0.2)" }}
            onClick={() => setAmount(amount + "0")}
          >
            0
          </button>
          <button
            className="flex justify-center items-center"
            style={{ width: "55px", height: "55px" }}
            onClick={() => setAmount(amount.slice(0, -1))}
          >
            <img src={eraseButton} alt="Erase Button" />
          </button>
        </div>

        <div className="flex justify-center w-full h-full mb-14">
          <button
            className="self-end w-full text-white py-5 rounded-full"
            style={{
              background: "linear-gradient(180deg, #272B22 0%, #353930 100%)",
              boxShadow:
                "0px 20px 13px rgba(39, 43, 34, 0.1), 0px 8.14815px 6.51852px rgba(39, 43, 34, 0.05), 0px 1.85185px 3.14815px rgba(39, 43, 34, 0.025)",
            }}
          >
            Next
          </button>
        </div>
      </div>
    </Container>
  );
};

export default DepositAmount;
