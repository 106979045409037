import React, { useState, useEffect } from "react";
import { Button, Header, Form, Modal } from "semantic-ui-react";
import axios from "axios";
import GetBackendURL from "../../../../constants";
import logoImg from "./img/redxam-logo.svg";
import { useHistory } from "react-router";
import localStorageService from "../../../services/localStorageService";
import { useDispatch, useSelector } from "react-redux";
import { verifyTokenSession } from "../../../redux/actions/UserActions";
import { Link } from "react-scroll";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import NightsStayIcon from "@material-ui/icons/NightsStay";
import WaitlistModel from "./WaitlistModal";

const Navbar = ({ background, logoColor, linksColor }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [response, setResponse] = useState(false);
  const [open, setOpen] = useState(false);
  const [navMobile, setNavMobile] = useState(false);
  const [currentTheme, setCurrentTheme] = useState(localStorageService.getItem("theme") || "light");
  const [loginLoading, setLoginLoading] = useState(false);

  const handleNavMobile = () => {
    if (!navMobile) setNavMobile(true);
    else setNavMobile(false);
  };

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
      setScrolling(e.target.documentElement.scrollTop > scrollTop);
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const submitEmail = async () => {
    if (validateEmail(email)) {
      setLoginLoading(true);
      const mutation = `
            mutation {
              updateToken(arg: {
                  email: "${email}"
                }) {
                    success
                    message
                }
            }
        `;
      const backendURL = GetBackendURL();
      axios
        .post(backendURL + mutation)
        .then((res) => {
          //console.log(res.data.data.updateToken);
          setSubmitted(true);
          setResponse(res.data.data.updateToken.success);
        })
        .finally(() => setLoginLoading(false));
    }
  };

  const validSession = () => {
    const token = localStorageService.getItem("token");
    if (token) {
      dispatch(verifyTokenSession(token, history));
    }
  };

  const toggleTheme = () => {
    if (!currentTheme || currentTheme === "light") {
      localStorageService.setItem("theme", "dark");
      setCurrentTheme("dark");
      document.body.classList.add("dark");
    } else {
      localStorageService.setItem("theme", "light");
      setCurrentTheme("light");
      document.body.classList.remove("dark");
    }
  };

  return (
    <div
      className="bar"
      style={scrollTop <= 0 && !navMobile ? { background: background } : { background: "#fff" }}
    >
      <div className="container">
        <nav className="nav">
          <a href="/">
            <div className="nav_logo">
              <img src={logoImg} alt="redxam Logo" />
              <h2
                style={
                  scrollTop <= 0
                    ? { color: document.body.classList.contains("dark") ? "#fff" : logoColor }
                    : { color: "#000" }
                }
              >
                redxam
              </h2>
            </div>
          </a>
          <ul className="nav_links">
            <a href="/about">
              <li style={scrollTop <= 0 ? { color: linksColor } : { color: "#000" }}>About</li>
            </a>
            <a className="cursor" href="/#benefits" spy={true} smooth={true}>
              <li style={scrollTop <= 0 ? { color: linksColor } : { color: "#000" }}>Benefits</li>
            </a>
            <a className="cursor" href="/#security" spy={true} smooth={true}>
              <li style={scrollTop <= 0 ? { color: linksColor } : { color: "#000" }}>Security</li>
            </a>
            <a
              href="#"
              onClick={() => {
                setOpen(true);
                validSession();
              }}
            >
              <li>Login</li>
            </a>
            <WaitlistModel referrer="navbar" />
            <button onClick={toggleTheme}>
              <li>{currentTheme === "dark" ? <WbSunnyIcon /> : <NightsStayIcon />}</li>
            </button>
          </ul>
          <div
            onClick={() => handleNavMobile()}
            className={navMobile ? "nav_mobile open" : "nav_mobile"}
          >
            <div className="nav_mobile_button"></div>
          </div>
        </nav>
      </div>
      <div className={navMobile ? "nav_mobile_menu open" : "nav_mobile_menu"}>
        <ul className="nav_mobile_menu_links">
          <button
            onClick={() => {
              toggleTheme();
              handleNavMobile();
              setOpen(false);
            }}
            style={{ marginBottom: "50px" }}
          >
            <li>{currentTheme === "dark" ? <WbSunnyIcon /> : <NightsStayIcon />}</li>
          </button>
          <a href="/about" onClick={() => handleNavMobile()}>
            <li>About</li>
          </a>
          <a onClick={() => handleNavMobile()} href="/#benefits" spy={true} smooth={true}>
            <li>Benefits</li>
          </a>
          <a onClick={() => handleNavMobile()} href="/#security" spy={true} smooth={true}>
            <li>Security</li>
          </a>
          <Link
            onClick={() => {
              handleNavMobile();
              setOpen(true);
            }}
            to="login"
            spy={true}
            smooth={true}
            className=""
          >
            <li>Login</li>
          </Link>
          <WaitlistModel referrer="mobile_navbar" callback={handleNavMobile} />
        </ul>
      </div>
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        style={{
          width: "100vh",
          height: "100vh",
        }}
        className="login_modal"
      >
        <Form>
          {!submitted ? (
            <div>
              <h3>Login to Redxam</h3>
              <p>Login for alpha and continue enjoying the best investments plan</p>
              <div className="input-wrap email">
                <label htmlFor="email" className="form-label email-label">
                  Email address
                </label>
                <input
                  type="text"
                  className="form-control email-input"
                  id="email"
                  aria-describedby="emailHelp"
                  placeholder="Enter email address"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="buttons">
                <Button
                  onClick={() => submitEmail()}
                  className="login-button disabled:opacity-30 disabled:cursor-not-allowed"
                  disabled={loginLoading}
                >
                  Login
                </Button>
              </div>
            </div>
          ) : (
            <div>
              {response ? (
                <div>
                  <h3>Link sent</h3>
                  <p>Please check your email inbox and click on login to continue.</p>
                </div>
              ) : (
                <div>
                  <h3>No active account</h3>
                  <p>
                    Sorry your account doesn’t exist yet. Please join the waitlist or email us at
                    hello@redxam.com if something is wrong.
                  </p>
                  <div className="buttons flex flex-col justify-center items-center">
                    <WaitlistModel referrer="login_popup" />
                    <a href="mailto:hello@redxam.com">
                      <button className="button mt-2">Email Us</button>
                    </a>
                  </div>
                </div>
              )}
            </div>
          )}
        </Form>
      </Modal>
    </div>
  );
};

export default Navbar;
