import React, { useState } from "react";
import Chart from "react-apexcharts";

// Components Imports
import Navbar from "./Navbar/Navbar";
import Internal from "../../../components/layouts/Internal"

// Styles Imports
import "../../../styles/profile/portfolio.sass";

// Imgs Imports
import cancelButton from "../../../images/cancelButton.svg";
import helpButton from "../../../images/helpButton.svg";
import radioButton from "../../../images/radioButton.svg";
import arrowCircleLeft from "../../../images/arrowCircleLeft.svg";
import arrowCircleRight from "../../../images/arrowCircleRight.svg";

const PortfolioScreen = () => {
  const [modeScreen, setModeScreen] = useState(1);
  const conservativePort = [
    {
      name: "USDT",
      percentage: "20%",
    },
    {
      name: "USDC",
      percentage: "20%",
    },
    {
      name: "DAI",
      percentage: "20%",
    },
    {
      name: "CURVE",
      percentage: "25%",
    },
    {
      name: "UNI LP",
      percentage: "15%",
    },
  ];

  const donutChart = {
    options: {
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#ffd000", "#ffb700", "#82ff47", "#ffea00", "#d0ea00"],
      legend: {
        show: false,
      },
      tooltip: { enabled: false },
    },
    series: modeScreen ? [40, 40, 40, 50, 30] : [110, 60, 20, 10],
  };

  const switchPortfolio = () => {
    setModeScreen((x) => !x);
  };

  const agressivePort = [
    {
      name: "USDT",
      percentage: "55%",
    },
    {
      name: "Defi Pulse Index",
      percentage: "30%",
    },
    {
      name: "ETH",
      percentage: "10%",
    },
    {
      name: "BTC",
      percentage: "5%",
    },
  ];

  const agressiveLegends = (
    <div className="donut_chart_legends">
      {agressivePort.map((x) => (
        <div className="donut_chart_legend">
          <div className="donut_chart_legend_info">
            <span className="dot"></span>
            <h2>{x.name}</h2>
          </div>
          <div className="donut_chart_legend_percentage">
            <h2>{x.percentage}</h2>
          </div>
        </div>
      ))}
    </div>
  );

  const conservativePortUI = (
    <div className="donut_chart_legends">
      {conservativePort.map((x) => (
        <div className="donut_chart_legend">
          <div className="donut_chart_legend_info">
            <span className="dot"></span>
            <h2>{x.name}</h2>
          </div>
          <div className="donut_chart_legend_percentage">
            <h2>{x.percentage}</h2>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <Internal>
      <Navbar title="Portfolio" backArrowDisplay="block" potentialDisplay="none" />
      <div className="container">
        <div
          className="portfolio"
          style={!modeScreen ? { background: "#FF9500" } : { background: "#FFF" }}
        >
          <div className="donut_chart">
            <div className="mobile_nav">
              <nav>
                <a href="#">
                  <img src={cancelButton} alt="" />
                </a>
                {!modeScreen ? (
                  <p>Aggressive</p>
                ) : (
                  <p style={{ color: "blue", fontSize: "10pt" }}>Conservative</p>
                )}
                <a href="#">
                  <img src={helpButton} alt="" />
                </a>
              </nav>
            </div>
            <div className="donut_chart_title">
              <h2>{!modeScreen ? "Aggressive" : "Conservative"}</h2>
            </div>
            <div className="mobile_mode_buttons">
              <button onClick={() => switchPortfolio()}>
                {modeScreen ? <img src={radioButton} alt="" /> : ""}
              </button>
              <button onClick={() => switchPortfolio()}>
                {!modeScreen ? <img src={radioButton} alt="" /> : ""}
              </button>
            </div>
            <div className="chart">
              <button onClick={() => switchPortfolio()}>
                <img src={arrowCircleLeft} alt="" />
              </button>
              <Chart
                options={donutChart.options}
                series={donutChart.series}
                type="donut"
                className="chart_donut"
              />
              <button onClick={() => switchPortfolio()}>
                <img src={arrowCircleRight} alt="" />
              </button>
            </div>
            {!modeScreen ? agressiveLegends : conservativePortUI}
            <div className="donut_chart_button">
              {!modeScreen ? (
                <a href="/portfolio#" onClick={(e) => e.preventDefault()}>
                  Disabled
                </a>
              ) : (
                <a href="/portfolio#" onClick={(e) => e.preventDefault()}>
                  Change Portfolio
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </Internal>
  );
};

export default PortfolioScreen;
