/* eslint-disable spellcheck/spell-checker */
import React from "react";

import maxProfile from "./img/max.jpg";
import alexProfile from "./img/alex.jpg";
import jhosephProfile from "./img/jhoseph.jpg";
import pruselaProfile from "./img/prusela.jpg";

const OurTeam = () => {
  const us = [
    { name: "Max Awad", picture: maxProfile, role: "CEO, Co-Founder" },
    { name: "Prusela Bhowmick", picture: pruselaProfile, role: "Marketing" },
    { name: "Alex Gonzalez", picture: alexProfile, role: "Engineering" },
    { name: "Jhoseph Guerrero", picture: jhosephProfile, role: "Engineering" },
    // { name: "Youssef Morsi", picture: null, role: "Engineering" },
  ];

  const usRendered = us.map((ur) => (
    <div className="mb-16 lg:mb-0">
      <img
        style={{ width: 250, height: 250, objectFit: "cover", borderRadius: "1rem" }}
        src={ur.picture}
        alt={ur.name}
      />
      <h5 className="my-1 text-2xl" style={{ color: "var(--colorBlack)" }}>
        {ur.name}
      </h5>
      <p>{ur.role}</p>
    </div>
  ));

  return (
    <div className="flex flex-column items-center lg:block">
      <h2>Our Team</h2>
      <div className="lg:grid grid-cols-4 gap-y-14">{usRendered}</div>
    </div>
  );
};

export default OurTeam;
