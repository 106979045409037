import { useState, useEffect } from "react";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
import EmailIcon from "@material-ui/icons/Email";
import SettingsIcon from "@material-ui/icons/Settings";
import SearchIcon from "@material-ui/icons/Search";
import NotificationsIcon from "@material-ui/icons/Notifications";
import StaffLogin from "./StaffLogin";
import Overview from "./Overview";
import Users from "./Users";
import Emails from "./Emails";
import CreateUser from "./CreateUser";
import localStorageService from "../../services/localStorageService";
import GetBackendURL from "../../../constants";
import axios from "axios";
import { MD5 } from "crypto-js";

export const Admin = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [activeSection, setActiveSection] = useState("overview");
  const [adminInfo, setAdminInfo] = useState({});

  const backendURL = GetBackendURL();

  useEffect(() => {
    (async () => {
      let adminToken = localStorageService.getItem("admin_token");

      try {
        if (!adminToken) {
          setLoggedIn(false);
        } else {
          let { data } = await axios.post(backendURL + "query { admin { email } }", null, {
            headers: { authorization: "Bearer " + adminToken },
          });

          if (data.data.admin.email) {
            setAdminInfo(data.data.admin);
            setLoggedIn(true);
          } else throw new Error("No admin info");
        }
      } catch {
        setLoggedIn(false);
        localStorageService.removeItem("admin_token");
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return !loading ? (
    !loggedIn || !adminInfo?.email ? (
      <StaffLogin setLoggedIn={setLoggedIn} setAdminInfo={setAdminInfo} />
    ) : (
      <div className="flex flex-row min-h-screen">
        <div
          style={{
            flex: 1,
            background: "linear-gradient(356.99deg, #8EE616 -77.71%, #3EB402 66.18%)",
          }}
          className="py-4 min-h-screen text-white"
        >
          <div className="px-6 mt-4 mb-8 text-2xl">Redxam</div>
          <ul className="flex flex-col">
            {[
              { name: "Overview", icon: DashboardIcon },
              { name: "Users", icon: PeopleIcon },
              { name: "Emails", icon: EmailIcon },
              { divider: true },
              { name: "Settings", icon: SettingsIcon },
            ].map((section) => {
              let id = !section?.divider && section.name.split(" ").join("_").toLowerCase();
              return section?.divider ? (
                <hr className="bg-white opacity-50" key={`divider_${Math.random()}`} />
              ) : (
                <li
                  key={id}
                  className={`py-4 px-6 cursor-pointer flex items-center ${
                    activeSection === id
                      ? "bg-white bg-opacity-10 border-l-2 border-white"
                      : "opacity-70"
                  }`}
                  onClick={() => setActiveSection(id)}
                >
                  <section.icon /> <span className="ml-2">{section.name}</span>
                </li>
              );
            })}
          </ul>
        </div>
        <div style={{ flex: 7 }} className="px-6 py-8">
          <div className="flex items-center">
            <div className="flex-1 ml-4">
              <h1 className="text-2xl">
                {activeSection[0].toUpperCase() + activeSection.replace(/\_/gm, " ").slice(1)}
              </h1>
            </div>
            <div className="flex-1 flex items-center justify-end">
              <div className="flex-1 flex justify-end pr-4 border-r border-black border-opacity-30 ">
                <SearchIcon />
                <NotificationsIcon className="ml-2" />
              </div>
              <div className="flex items-center px-4">
                <span>{adminInfo.email}</span>
                <img
                  src={`https://www.gravatar.com/avatar/${MD5(
                    adminInfo.email.toLowerCase().trim()
                  )}?s=32`}
                  title="avatar from gravatar.com"
                  alt={adminInfo.email}
                  className="rounded-full ml-4"
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end py-4 mx-4">
            <a
              href="#"
              onClick={() => setActiveSection("create_user")}
              style={{ border: "1px solid rgb(62,180,2)", color: "rgba(62,180,2,1)" }}
              className="py-3 px-6 rounded-xl transition-opacity duration-300 hover:opacity-70"
            >
              Create user
            </a>
            <a
              href="/"
              style={{ border: "1px solid rgb(62,180,2)", backgroundColor: "rgba(62,180,2,1)" }}
              className="py-3 px-6 rounded-xl transition-opacity duration-300 hover:opacity-70 text-white ml-4"
            >
              New deposit
            </a>
          </div>
          {activeSection === "overview" ? (
            <Overview />
          ) : activeSection === "users" ? (
            <Users />
          ) : activeSection === "create_user" ? (
            <CreateUser setActiveSection={setActiveSection} />
          ) : activeSection === "emails" ? (
            <Emails />
          ) : null}
        </div>
      </div>
    )
  ) : (
    <span>loading</span>
  );
};

export default Admin;
