import React, { useState } from "react";
import { Icon, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import Internal from "../../../components/layouts/Internal";
import Navbar from "../main/Navbar/Navbar";

const Personal = () => {
  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked((old) => {
      return !old;
    });
  };

  return (
    <Internal>
      <Navbar title="Personal" backArrowDisplay="block" potentialDisplay="flex" />

      <div className="settings">
        <div className="text-center settingTitle">Personal Area</div>
        <div className="settingOptions">
          <h3>Personal Info</h3>
          <div className="details">
            <div className="infoLabel">First Name</div>
            <div className="infoDetail">John</div>
          </div>
          <div className="details">
            <div className="infoLabel">Last Name</div>
            <div className="infoDetail">Doe</div>
          </div>
          <div className="details">
            <div className="infoLabel">Email</div>
            <div className="infoDetail">sample@sample.com</div>
          </div>
          <h3>Phone Number</h3>
          <div className="setting">
            <Link to="#" className="unDecoration setLink">
              <div>(205) 555-0100</div>
              <div className="arrowIcon">
                <p>Verified</p>
                <Icon>chevron_right</Icon>
              </div>
            </Link>
          </div>
          <h3>Residential Address</h3>
          <div className="details">
            <div className="infoDetail">6391 Elgin St. Celina Delaware 10299</div>
          </div>
          <div className="withdrawBtn cancelBtn contentCenter">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className="withdrawSubmit logoutBtn"
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </Internal>
  );
};

export default Personal;
