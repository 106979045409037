import React from "react";

import { useSelector } from "react-redux";

// Components Imports
import Navbar from "../main/Navbar/Navbar";
import Internal from "../../../components/layouts/Internal";
import PerformanceChart from "../../../components/charts/PerfomanceChart";

// Styles Imports
import "../../../styles/main/performance.sass";

const PerfomanceScreen = () => {
  const user = useSelector((state) => state.home.user);
  console.log(user);

  const startingPrice = user.performanceRecords.userPerformanceRecords[0].balance;

  const profitBalance =
    user.performanceRecords.userPerformanceRecords[
      user.performanceRecords.userPerformanceRecords.length - 1
    ].balance;

  return (
    <Internal>
      <Navbar title="Performance" backArrowDisplay="block" potentialDisplay="none" />

      <div className="container relative">
        <div className="overlay_performance absolute flex justify-center items-center">
          <p className="text-6xl text-rubik font-bold text-black">Coming Soon</p>
        </div>
        <PerformanceChart />
        <div className="info" style={{ margin: "0 auto" }}>
          <div className="info_row">
            <h3>Starting Balance</h3>
            <h3>{startingPrice.toFixed(5)}</h3>
          </div>
          <div className="info_row">
            <h3>Profit Balance</h3>
            <h3>{profitBalance.toFixed(5)}</h3>
          </div>
          <div className="info_row">
            <h3>Month-To-Date Range</h3>
            <h3>{`${startingPrice.toFixed(5)}-${profitBalance.toFixed(5)}`}</h3>
          </div>
          <div className="info_row">
            <h3>Month-To-Date Return</h3>
            <h3>{`${((profitBalance.toFixed(5) / startingPrice.toFixed(5)) * 100).toFixed(
              5
            )}%`}</h3>
          </div>
          <div className="info_row">
            <h3>Year-To-Date Return</h3>
            <h3>-2%</h3>
          </div>

          {/* Featured Disabled for now */}

          {/* <div className="featured">
            <h2>Featured</h2>
            <div className="featured_cards">
              <div className="featured_card">
                <h2>ASD</h2>
                <h4>Lacinia</h4>
              </div>
              <div className="featured_card">
                <h2>ASD</h2>
                <h4>Lacinia</h4>
              </div>
              <div className="featured_card">
                <h2>ASD</h2>
                <h4>Lacinia</h4>
              </div>
              <div className="featured_card">
                <h2>ASD</h2>
                <h4>Lacinia</h4>
              </div>
              <div className="featured_card">
                <h2>ASD</h2>
                <h4>Lacinia</h4>
              </div>
              <div className="featured_card">
                <h2>ASD</h2>
                <h4>Lacinia</h4>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </Internal>
  );
};

export default PerfomanceScreen;
