/* eslint-disable spellcheck/spell-checker */
import React from "react";
import ReactApexChart from "react-apexcharts";
import ApexCharts from "apexcharts";
import data from "./testData.json";
import Compound from "./compound.lib";
import { takeRight } from "lodash";

const formatData = (supplyRates) =>
  supplyRates.map((block) => [block.block_timestamp * 1000, Number((block.rate * 100).toFixed(2))]);

class ApexChart extends React.Component {
  constructor(props) {
    super(props);
    const now = new Date().getTime();
    this.state = {
      average: 0,
      averageUSDT: 0,
      averageUSDC: 0,
      averageDAI: 0,
      series: [],
      options: {
        yaxis: { min: 0 },
        chart: {
          id: "area-datetime",
          type: "area",
          height: 350,
          zoom: {
            enabled: true,
            type: "xy",
          },
        },
        annotations: {
          yaxis: [
            {
              y: 0.06,
              borderColor: "#999",
              label: {
                show: true,
                text: "National Average",
                style: {
                  color: "#fff",
                  background: "#00E396",
                },
              },
            },
          ],
          xaxis: [
            {
              x: new Date("14 Apr 2021").getTime(),
              borderColor: "#999",
              yAxisIndex: 0,
              label: {
                show: true,
                text: "Rally",
                style: {
                  color: "#fff",
                  background: "#775DD0",
                },
              },
            },
          ],
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
          style: "hollow",
        },
        xaxis: {
          type: "datetime",
          min: now - 3 * 30 * 24 * 3600 * 1000,
          tickAmount: 6,
        },
        tooltip: {
          x: {
            format: "dd MMM yyyy",
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 100],
          },
        },
      },

      selection: "one_year",
    };
  }

  async componentDidMount() {
    const dai = await Compound.getLast30DaysSimple(Compound.cDAI);
    const usdc = await Compound.getLast30DaysSimple(Compound.cUSDC);
    const usdt = await Compound.getLast30DaysSimple(Compound.cUSDT);
    let daiRates = formatData(dai);
    let usdcRates = formatData(usdc);
    let usdtRates = formatData(usdt);
    let compoundMixRates = usdtRates.map((v, i) => {
      let mixAvg = (usdtRates[i][1] * 0.5 + usdcRates[i][1] * 0.25 + daiRates[i][1] * 0.25).toFixed(
        2
      );
      return [v[0], mixAvg];
    });
    // //console.log(compoundMixRates);
    const now = new Date().getTime();
    const BoA = [];
    let averageUSDT = usdtRates.map((v) => v[1]).reduce((a, b) => a + b, 0) / 120;
    let averageUSDC = usdcRates.map((v) => v[1]).reduce((a, b) => a + b, 0) / 120;
    let averageDAI = daiRates.map((v) => v[1]).reduce((a, b) => a + b, 0) / 120;
    let average = 0 + averageUSDT * 0.5 + averageUSDC * 0.25 + averageDAI * 0.25;
    this.setState({ average, averageUSDT, averageUSDC, averageDAI });

    for (let i = 0; i < 90; i++) {
      BoA.push([now - i * 24 * 3600 * 1000, 0.01]);
    }
    this.setState({
      series: [
        { name: "DAI", data: daiRates },
        { name: "USDC", data: usdcRates },
        { name: "USDT", data: usdtRates },
        { name: "Compound Weighted Rate", data: compoundMixRates },
        {
          name: "Bank of America",
          data: BoA,
        },
        {
          name: "Chase",
          data: BoA,
        },
        {
          name: "TD Bank",
          data: BoA,
        },
      ],
    });
  }

  updateData(timeline) {
    this.setState({
      selection: timeline,
    });

    const now = new Date().getTime();
    switch (timeline) {
      case "one_hour":
        ApexCharts.exec("area-datetime", "zoomX", now - 3600 * 1000, now);
        break;
      case "one_day":
        ApexCharts.exec("area-datetime", "zoomX", now - 24 * 3600 * 1000, now);
        break;
      case "one_week":
        ApexCharts.exec("area-datetime", "zoomX", now - 7 * 24 * 3600 * 1000, now);
        break;
      case "one_month":
        ApexCharts.exec("area-datetime", "zoomX", now - 30 * 24 * 3600 * 1000, now);
        break;
      case "three_months":
        ApexCharts.exec("area-datetime", "zoomX", now - 3 * 30 * 24 * 3600 * 1000, now);
        break;
      case "six_months":
        ApexCharts.exec("area-datetime", "zoomX", now - 6 * 30 * 24 * 3600 * 1000, now);
        break;
      case "ytd":
        ApexCharts.exec("area-datetime", "zoomX", new Date("01 Jan 2021").getTime(), now);
        break;
      case "all":
        ApexCharts.exec("area-datetime", "zoomX", new Date("12 Sep 2020").getTime(), now);
        break;
      default:
    }
  }

  render() {
    return (
      <div id="chart">
        <div class="toolbar">
          <button
            id="one_hour"
            onClick={() => this.updateData("one_hour")}
            className={this.state.selection === "one_hour" ? "active" : ""}
          >
            1H
          </button>
          &nbsp;
          <button
            id="one_day"
            onClick={() => this.updateData("one_day")}
            className={this.state.selection === "one_day" ? "active" : ""}
          >
            1D
          </button>
          &nbsp;
          <button
            id="one_week"
            onClick={() => this.updateData("one_week")}
            className={this.state.selection === "one_week" ? "active" : ""}
          >
            1W
          </button>
          &nbsp;
          <button
            id="one_month"
            onClick={() => this.updateData("one_month")}
            className={this.state.selection === "one_month" ? "active" : ""}
          >
            1M
          </button>
          &nbsp;
          <button
            id="three_months"
            onClick={() => this.updateData("three_months")}
            className={this.state.selection === "three_months" ? "active" : ""}
          >
            3M
          </button>
          &nbsp;
          <button
            id="six_months"
            onClick={() => this.updateData("six_months")}
            className={this.state.selection === "six_months" ? "active" : ""}
          >
            6M
          </button>
          &nbsp;
          <button
            id="ytd"
            onClick={() => this.updateData("ytd")}
            className={this.state.selection === "ytd" ? "active" : ""}
          >
            YTD
          </button>
          &nbsp;
          <button
            id="all"
            onClick={() => this.updateData("all")}
            className={this.state.selection === "all" ? "active" : ""}
          >
            ALL
          </button>
        </div>
        <div id="chart-timeline">
          <ReactApexChart
            options={this.state.options}
            series={this.state.series}
            type="area"
            height={350}
          />
        </div>
        <div
          style={{
            borderRadius: 20,
            border: "0px solid #a9abae",
            backgroundColor: "#fcfcfc",
            boxSizing: "border-box",
          }}
        >
          <h1>Last 30 Day</h1>
          <h2>Compound Finance</h2>
          <p>Average Compound: {this.state.average}</p>
          <p>Average averageUSDT: {this.state.averageUSDT}</p>
          <p>Average averageUSDC: {this.state.averageUSDC}</p>
          <p>Average averageDAI: {this.state.averageDAI}</p>
        </div>
      </div>
    );
  }
}

export default function DataVisualizer() {
  return (
    <div>
      Data
      <ApexChart />
    </div>
  );
}
