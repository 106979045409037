import axios from "axios";
import GetBackendURL from "../../../constants";

export const GET_LEVEL = "GET_LEVEL";
export const GO_UP_LEVEL = "GO_UP_LEVEL";
export const GET_WAITLIST = "GET_WAITLIST";
export const CREATE_REFERRER = "CREATE_REFERRER";
export const SIGNUP_WAITLIST = "SIGNUP_WAITLIST";
export const WAITLIST_LOADING = "WAITLIST_LOADING";
export const WAITLIST_CLEAR_ERROR = "WAITLIST_CLEAR_ERROR";
export const WAITLIST_ERROR = "WAITLIST_ERROR";

export function get_around_number() {
  return (dispatch, getState) => {
    dispatch({ type: WAITLIST_LOADING });
    const query = `
            query {
                user {
                    level
                }
            }
        `;
    const token = localStorage.getItem("token");
    const backendURL = GetBackendURL();
    axios.post(backendURL + query, {}, { headers: { access_token: token } }).then((res) => {
      if (res.data.data.user) {
        //
        dispatch({
          type: GET_LEVEL,
          level: res.data.data.user[0].level,
        });
      } else {
        dispatch({
          type: WAITLIST_ERROR,
        });
      }
    });
  };
}

export function goUpLevel(level) {
  return (dispatch, getState) => {
    dispatch({ type: WAITLIST_LOADING });
    //console.log("working!" + level);
    const mutation = `
            mutation {
                goUpQueue {
                    level
                    message
                    success
                }
            }
        `;
    if (level > 1) {
      dispatch({
        type: GO_UP_LEVEL,
        level: level - 1,
      });
    }
    const backendURL = GetBackendURL();
    const token = localStorage.getItem("token");
    axios.post(backendURL + mutation, {}, { headers: { access_token: token } }).then((res) => {
      if (res.data.data.goUpQueue.success) {
        dispatch({
          type: GO_UP_LEVEL,
          level: res.data.data.goUpQueue.level,
        });
        //console.log(res.data.data.goUpQueue.level);
      } else {
        dispatch({
          type: WAITLIST_ERROR,
        });
      }
    });
  };
}

export function getWaitlist() {
  return (dispatch, getState) => {
    dispatch({ type: WAITLIST_LOADING });
    const query = `
            query {
                getWaitList {
                    referrers
                    statuses
                }
            }
        `;
    const backendURL = GetBackendURL();
    const token = localStorage.getItem("token");
    axios.post(backendURL + query, {}, { headers: { access_token: token } }).then((res) => {
      if (res.data.data.getWaitList) {
        //console.log(res.data.data.getWaitList);
        dispatch({
          type: GET_WAITLIST,
          waitlist: res.data.data.getWaitList,
        });
      } else {
        dispatch({
          type: WAITLIST_ERROR,
        });
      }
    });
  };
}

export function createReferrer(email) {
  return (dispatch, getState) => {
    dispatch({ type: WAITLIST_LOADING });
    const token = localStorage.getItem("token");
    const mutation = `
            mutation {
                sendReferral(arg: {email: "${email}", token: ${token}}) {
                    message
                    success
                }
            }
        `;
    const backendURL = GetBackendURL();
    axios
      .post(backendURL + mutation)
      .then((res) => {
        //
        if (res.data.data.sendReferral.success) {
          //console.log(res.data.data.sendReferral);
          dispatch({
            type: CREATE_REFERRER,
            newReferrer: email,
          });
        } else {
          dispatch({
            type: WAITLIST_ERROR,
          });
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };
}

export function waitlist(email) {
  return (dispatch, getState) => {
    //console.log(email);
    dispatch({ type: WAITLIST_LOADING });
    const mutation = `
            mutation {
                createWaitlist(arg: {email: "${email}"}) {
                    success
                    message
                }
            }
        `;
    const backendURL = GetBackendURL();
    axios.post(backendURL + mutation).then((res) => {
      //console.log(res.data.data.createWaitlist);
      if (res.data.data.createWaitlist.success) {
        //console.log(res.data.data.createWaitlist);
        dispatch({
          type: SIGNUP_WAITLIST,
        });
      } else {
        dispatch({
          type: WAITLIST_ERROR,
        });
      }
    });
  };
}
