import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Icon } from "@material-ui/core";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Navbar from "../../main/Navbar/Navbar";
import NavbarMobile from "../../main/Navbar/NavbarMobile";
import Chart from "react-apexcharts";

import depositIcon from "./deposit_icon.svg";
import withdrawIcon from "./withdraw_icon.svg";
import "./home.css";
import InternalLayout from "../../../../components/layouts/Internal";
import { useHistory } from "react-router-dom";

const parseToStringValue = (amount) => {
  if (amount >= 1000) return (amount / 1000).toFixed(2) + "K";
  return amount.toFixed(2);
};

const Home = () => {
  useEffect(() => {
    function getData() {
      console.log("on load");
    }
  }, []);

  const history = useHistory();
  const homeData = useSelector((state) => state.home.home) || {};
  const isLoading = useSelector((state) => state.home.loading);

  if (!homeData) {
    alert("No homedata");
    if (!isLoading) {
      history.push("/login");
      return null;
    }
    return null;
  }
  let balance = [];
  let timestamp = [];

  const chartSeries = [{ name: "series1", data: balance }];

  const chartOptions = {
    chart: {
      width: "100%",
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    xaxis: {
      type: "numeric",
      categories: timestamp,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    colors: ["#fff"],
    grid: { show: false },
  };
  const user = {};

  return (
    <Container className="dashboard" fluid={true}>
      <InternalLayout>
        <Navbar title="Home" backArrowDisplay="none" potentialDisplay="flex" />

        <Row className="align-items-center justify-content-center">
          <Col lg={4}>
            <Card>
              <CardBody>
                <NavbarMobile title={"user.firstName"} />
                <div className="crypto-value">
                  <p className="px-2">Your crypto account value is:</p>
                  <b className="px-3">
                    ${parseToStringValue((homeData.homepage && homeData.homepage.balance) || 0)}
                  </b>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xs={6} lg={2}>
            <Link className="homeLink" to="/deposit">
              <Card>
                <CardBody>
                  <div className="transaction-box">
                    <img alt="deposit" src={depositIcon} className="img-fluid float-right" />
                    <b>Deposit</b>
                  </div>
                </CardBody>
              </Card>
            </Link>
          </Col>
          <Col xs={6} lg={2}>
            <Link className="homeLink" to="/withdrawal">
              <Card>
                <CardBody>
                  <div className="transaction-box">
                    <img alt="withdraw" src={withdrawIcon} className="img-fluid float-right" />
                    <b>Withdraw</b>
                  </div>
                </CardBody>
              </Card>
            </Link>
          </Col>
          <Col lg={4}>
            <Card>
              <CardBody>
                <Row className="align-items-center justify-content-between">
                  <Col>
                    <div>
                      <b>Portfolio</b>
                    </div>
                  </Col>
                  <Col>
                    <div className="text-muted float-right">Profit</div>
                  </Col>
                </Row>
                <Row className="align-items-center justify-content-between mt-2">
                  <Col>
                    <h3>
                      <b className="portfolio-amount">
                        ${(homeData.homepage && homeData.homepage.dolarChange.toFixed(2)) || 0}
                      </b>
                    </h3>
                  </Col>
                  <Col>
                    <div className="float-right align-items-center flex">
                      <Icon
                        className="mr-2 mainColor"
                        style={{
                          position: "relative",
                          fontSize: "29px",
                          width: "20px",
                        }}
                      >
                        arrow_drop_up
                      </Icon>
                      <h3 className="d-inline-block">
                        <b className="portfolio-amount">
                          {(homeData &&
                            homeData.homepage &&
                            homeData.homepage.percentChange &&
                            homeData.homepage.percentChange.toFixed(2)) ||
                            0}
                          %
                        </b>
                      </h3>
                    </div>
                  </Col>
                </Row>
                <Link className="homeLink agressiveLink" to="/portfolio">
                  <Row className="align-items-center justify-content-between mt-2">
                    <Col>
                      <p className="mainColor">Aggressive</p>
                    </Col>
                    <Col>
                      <p>
                        <Icon className="mainColor float-right">keyboard_arrow_right</Icon>
                      </p>
                    </Col>
                  </Row>
                </Link>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col lg={4}>
            <Card className="performance-chart" style={{ height: "274px" }}>
              <div className="overlay_performance_home absolute flex justify-center items-center">
                <p className="text-6xl text-rubik font-bold text-black">Coming Soon</p>
              </div>
              <CardBody style={{ padding: "0" }}>
                <Link className="homeLink" to="/performance">
                  <h4 className="mt-6">Performance</h4>
                  <Chart options={chartOptions} series={chartSeries} type="line" height="200px" />
                </Link>
              </CardBody>
            </Card>
          </Col>
          <Col lg={8}>
            <Card className="acc-info text-white">
              <CardBody>
                <h4 className="mb-5">General account information</h4>
                <Row className="mb-3 hide-mobile">
                  <Col>
                    <div>Total amount:</div>
                  </Col>
                  <Col>
                    <div className="float-right">
                      <b>{(homeData.homepage && homeData.homepage.balance.toFixed(2)) || 0}</b>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xs={8}>
                    <div className="all-time-text">Deposited all time:</div>
                  </Col>
                  <Col xs={4}>
                    <div className="float-right">
                      <b>${user.deposited || 0}</b>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={8}>
                    <div>Withdrawn all time:</div>
                  </Col>
                  <Col xs={4}>
                    <div className="float-right">
                      <b>${user.withdrawn || 0}</b>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="justify-content-center mt-4">
          <Col lg={4} className="text-center">
            <h4>
              <b>If you like redxam, pass the word!</b>
            </h4>
            <div>
              To make it worth the trouble, we have an amazing{" "}
              <a href="/share" className="mainColor">
                referral program
              </a>{" "}
              for you!
            </div>
          </Col>
        </Row>
      </InternalLayout>
    </Container>
  );
};

export default Home;
