import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  Icon,
  CardContent,
  Button,
  CircularProgress,
  Snackbar,
  InputAdornment,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import { phoneLogin, phoneVerify } from "../../redux/actions/UserActions";
import { Alert } from "@material-ui/lab";
import localStorageService from "../../services/localStorageService";
import { useHistory } from "react-router";

const PhoneVerify = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const formRef = useRef();

  const [code, setCode] = useState("");
  const [phone, setPhone] = useState("");
  const [first, setFirst] = useState(true);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);

  const userData = useSelector((state) => {
    return state.user;
  });

  useEffect(() => {
    const phoneNumber = localStorageService.getItem("phoneNumber");
    if (phoneNumber) setPhone(phoneNumber);
    else window.location = "/phone";
  }, []);

  useEffect(() => {
    if (userData.success) {
      setOpen(true);
      setMessage(userData.error);
      setSuccess(true);
      setFirst(false);
    }
    if (!userData.success && userData.error) {
      setOpen(true);
      setMessage(userData.error);
      setSuccess(false);
      setFirst(false);
    }
  }, [userData.success, userData.error]);

  const handleSubmit = () => {
    const data = {
      code: code,
      phone: phone,
    };
    dispatch(phoneVerify(data));
  };

  const handleChange = (event) => {
    event.persist();
    setCode(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="container loginContainer">
      {userData.loading && (
        <div className="loadingWhole">
          <CircularProgress size="30px" />
        </div>
      )}
      <Card className="loginCard">
        <CardContent>
          <div className="contentCenter loginLogo">
            <img src="/assets/images/short_logo.png" />
          </div>
          <h1 className="text-center title">Please enter your security code.</h1>
          <Snackbar
            open={open && !first}
            className="toastDiv"
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert onClose={handleClose} severity={success ? "success" : "error"}>
              {message}
            </Alert>
          </Snackbar>
          <ValidatorForm
            ref={formRef}
            onSubmit={handleSubmit}
            onError={(errors) => null}
            className="profileForm"
          >
            <TextValidator
              className="emailInput phoneInput phoneVerifyInput"
              label="Security Code"
              onChange={handleChange}
              type="text"
              name="code"
              variant="outlined"
              value={code}
              validators={["required"]}
              errorMessages={["This field is required"]}
            />
            <div className="loginTwoBtn">
              <Button variant="contained" color="primary" type="submit" className="loginBtn">
                <Icon>code</Icon>&nbsp;<span className="pl-8 capitalize">Verify</span>
              </Button>
              <Button variant="text" color="primary" type="submit" className="loginBtn extraLink">
                <Icon>email</Icon>&nbsp;
                <Link to="/phone" className="registerLink">
                  Email Login
                </Link>
              </Button>
            </div>
            <div className="loginBtn extraLink">
              <span className="blackText">Don't have an account?</span>&nbsp;
              <Link to="/register" className="registerLink">
                Register
              </Link>
            </div>
          </ValidatorForm>
        </CardContent>
      </Card>
    </div>
  );
};

export default PhoneVerify;
