import React from "react";
import investors1 from "./img/investors1.svg";
import investors2 from "./img/investors2.svg";
import investors3 from "./img/investors3.svg";
import investors4 from "./img/investors4.svg";
import investors5 from "./img/investors5.svg";
import investors6 from "./img/investors6.svg";
import investors7 from "./img/investors7.svg";
import investors8 from "./img/investors8.svg";
import investors9 from "./img/investors9.svg";
import investors10 from "./img/investors10.svg";
import waves from "./img/waves.svg";
import localStorageService from "../../../../../services/localStorageService.js";

const Trusted = () => {
  return (
    <section className="trusted relative">
      <h2 className="text-center">Trusted by the worlds best investors</h2>
      <div className="grid grid-cols-2 lg:grid-cols-5 gap-y-20 ">
        <img src={investors1} alt="" className="my-0 mx-auto" />
        <img src={investors2} alt="" className="my-0 mx-auto" />
        <img src={investors3} alt="" className="my-0 mx-auto" />
        <img src={investors4} alt="" className="my-0 mx-auto" />
        <img src={investors5} alt="" className="my-0 mx-auto" />
        <img src={investors6} alt="" className="my-0 mx-auto" />
        <img src={investors7} alt="" className="my-0 mx-auto" />
        <img src={investors8} alt="" className="my-0 mx-auto" />
        <img src={investors9} alt="" className="my-0 mx-auto" />
        <img src={investors10} alt="" className="my-0 mx-auto" />
      </div>
      <img
        src={waves}
        className="absolute w-screen h-screen right-0"
        style={{
          top: "-10rem",
          zIndex: -1,
          display: localStorageService.getItem("theme") === "dark" ? "none" : "",
        }}
      />
    </section>
  );
};

export default Trusted;
