import React, { useState } from "react";
import { Icon, Button, CircularProgress, Snackbar } from "@material-ui/core";
import { Link } from "react-router-dom";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Alert } from "@material-ui/lab";
import MuiPhoneNumber from "material-ui-phone-number";

const PhoneLogin = () => {
  const [phoneBefore, setPhoneBefore] = useState("");
  const [phone, setPhone] = useState("");
  const [first, setFirst] = useState(true);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);

  const handleSubmit = () => {
    // let phoneNumber = phoneBefore + phone;
    // dispatch(phoneLogin({ phone: phone }));
  };

  const handleChange = (event) => {
    event.persist();
    setPhone(event.target.value);
  };

  const handlePhoneBefore = (value) => {
    const phoneNumber = value
      .replaceAll(" ", "")
      .replaceAll("(", "")
      .replaceAll(")", "")
      .replaceAll("-", "");
    setPhone(phoneNumber);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="container loginContainer">
      <div className="contentCenter loginLogo">
        <img src="/assets/images/short_logo.png" />
      </div>
      <h1 className="text-center title">Login With Phone</h1>
      <Snackbar
        open={open && !first}
        className="toastDiv"
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity={success ? "success" : "error"}>
          {message}
        </Alert>
      </Snackbar>
      <ValidatorForm onSubmit={handleSubmit} onError={(errors) => null} className="profileForm">
        <MuiPhoneNumber
          className="emailInput phoneInput"
          defaultCountry={"us"}
          onChange={handlePhoneBefore}
          variant="outlined"
          validators={["required"]}
          errormessages={["This field is required"]}
        />
        <div className="registerBtn">
          <Button variant="contained" color="primary" type="submit" className="loginBtn">
            <Icon>phone</Icon>&nbsp;
            <span className="pl-8 capitalize">Phone Login</span>
          </Button>
        </div>
        <div className="loginTwoBtn">
          <Button variant="text" color="primary" type="submit" className="loginBtn extraLink">
            <Icon>email</Icon>&nbsp;
            <Link to="/login" className="registerLink">
              Email Login
            </Link>
          </Button>
        </div>
        <div className="loginBtn extraLink">
          <span className="blackText">Don't have an account?</span>&nbsp;
          <Link to="/register" className="registerLink">
            Register
          </Link>
        </div>
      </ValidatorForm>
    </div>
  );
};

export default PhoneLogin;
