import {
  USER_LOGIN,
  USER_VERIFY,
  USER_PHONELOGIN,
  USER_PHONEVERIFY,
  USER_REGISTER,
  GET_USERS,
  USER_LOADING,
  USER_CLEAR_ERROR,
  USER_ERROR,
} from "../actions/UserActions";
import localStorageService from "../../services/localStorageService";

const initialState = {
  loading: false,
  user: null,
  users: null,
  success: false,
  error: false,
};

const userReducer = function (state = initialState, action) {
  switch (action.type) {
    case USER_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case USER_LOGIN: {
      return {
        ...state,
        loading: false,
        ...action,
      };
    }
    case USER_VERIFY: {
      return {
        ...state,
        loading: false,
        ...action,
      };
    }
    case USER_PHONELOGIN: {
      return {
        ...state,
        loading: false,
        ...action,
      };
    }
    case USER_PHONEVERIFY: {
      return {
        ...state,
        loading: false,
        ...action,
      };
    }
    case USER_REGISTER: {
      return {
        ...state,
        loading: false,
        ...action,
      };
    }
    case GET_USERS: {
      return {
        ...state,
        loading: false,
        ...action,
      };
    }
    case USER_CLEAR_ERROR: {
      return {
        ...state,
        success: false,
        error: false,
      };
    }
    case USER_ERROR: {
      return {
        ...state,
        user: null,
        loading: false,
        ...action,
      };
    }
    default:
      return state;
  }
};

export default userReducer;
