import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../../Navbar";
import Footer from "../../Footer";
import CareersContent from "./CareersContent";
import { Link } from "react-router-dom";

const Careers = () => {
  return (
    <div className="landing">
      <Helmet>
        <title>redxam | Careers</title>
      </Helmet>
      <Navbar background="#fff" logoColor="#000" linksColor="#000" />
      <div className="flex justify-center my-0 mx-auto about-content">
        <div style={{ background: "#FCFCFC" }} className="rounded-3xl">
          <Link to="/about">
            <button className="font-dmsans rounded-3xl text-base p-2 text-colorBlackLight mb-0 w-28">
              About Us
            </button>
          </Link>
          <Link to="/careers">
            <button className="font-dmsans rounded-3xl text-base p-2 text-colorBlackLight mb-0 w-28 bg-greenButtons">
              Careers
            </button>
          </Link>
          <Link to="/blog">
            <button className="font-dmsans rounded-3xl text-base p-2 text-colorBlackLight mb-0 w-28">
              Blog
            </button>
          </Link>
        </div>
      </div>
      <CareersContent />
      <Footer />
    </div>
  );
};

export default Careers;
