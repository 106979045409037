import React from "react";
import { Divider, Grid, Icon } from "@material-ui/core";
import { Link } from "react-router-dom";
import Internal from "../../../components/layouts/Internal";
import Navbar from "../main/Navbar/Navbar";

const Wallets = () => {
  return (
    <Internal>
      <Navbar title="Linked Wallets" backArrowDisplay="block" potentialDisplay="none" />
      <div className="wallets">
        <div className="walletLists">
          <div className="walletList">
            <Grid container spacing={3} alignItems="center">
              <Grid item lg={3} md={3} sm={3} xs={12} className="avatarContainer">
                <img src="/assets/images/qrcode.png" width="100%" />
              </Grid>
              <Grid item lg={9} md={9} sm={9} xs={12}>
                <h3>NdigEI32KGi85Odfis4JFOid94ODkdgow84Id9</h3>
              </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="center">
              <Grid item lg={3} md={3} sm={3} xs={12}></Grid>
              <Grid item lg={9} md={9} sm={9} xs={12} className="upDownWallet">
                <div className="upWallet">
                  <Icon>call_made</Icon>
                  <div>
                    <p>0.3545 BTC</p>
                    <p>17000,05 USD</p>
                  </div>
                </div>
                <div className="downWallet">
                  <Icon>call_received</Icon>
                  <div>
                    <p>0.3545 BTC</p>
                    <p>17000,05 USD</p>
                  </div>
                </div>
              </Grid>
            </Grid>
            <Divider />
          </div>
          <div className="walletList">
            <Grid container spacing={3} alignItems="center">
              <Grid item lg={3} md={3} sm={3} xs={12} className="avatarContainer">
                <img src="/assets/images/qrcode.png" width="100%" />
              </Grid>
              <Grid item lg={9} md={9} sm={9} xs={12}>
                <h3>NdigEI32KGi85Odfis4JFOid94ODkdgow84Id9</h3>
              </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="center">
              <Grid item lg={3} md={3} sm={3} xs={12}></Grid>
              <Grid item lg={9} md={9} sm={9} xs={12} className="upDownWallet">
                <div className="upWallet">
                  <Icon>call_made</Icon>
                  <div>
                    <p>0.3545 BTC</p>
                    <p>17000,05 USD</p>
                  </div>
                </div>
                <div className="downWallet">
                  <Icon>call_received</Icon>
                  <div>
                    <p>0.3545 BTC</p>
                    <p>17000,05 USD</p>
                  </div>
                </div>
              </Grid>
            </Grid>
            <Divider />
          </div>
          <div className="walletList">
            <Grid container spacing={3} alignItems="center">
              <Grid item lg={3} md={3} sm={3} xs={12} className="avatarContainer">
                <img src="/assets/images/qrcode.png" width="100%" />
              </Grid>
              <Grid item lg={9} md={9} sm={9} xs={12}>
                <h3>NdigEI32KGi85Odfis4JFOid94ODkdgow84Id9</h3>
              </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="center">
              <Grid item lg={3} md={3} sm={3} xs={12}></Grid>
              <Grid item lg={9} md={9} sm={9} xs={12} className="upDownWallet">
                <div className="upWallet">
                  <Icon>call_made</Icon>
                  <div>
                    <p>0.3545 BTC</p>
                    <p>17000,05 USD</p>
                  </div>
                </div>
                <div className="downWallet">
                  <Icon>call_received</Icon>
                  <div>
                    <p>0.3545 BTC</p>
                    <p>17000,05 USD</p>
                  </div>
                </div>
              </Grid>
            </Grid>
            <Divider />
          </div>
        </div>
      </div>
    </Internal>
  );
};

export default Wallets;
