import { useState } from "react";
import { Button, Header, Form, Modal } from "semantic-ui-react";
import check from "./img/check.svg";
import axios from "axios";
import GetBackendURL from "../../../../constants";

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export default function WaitlistModel({ referrer, callback }) {
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const [submitted, setSubmitted] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const submitEmail = async () => {
    if (validateEmail(email)) {
      setLoading(true);
      const mutation = `
    mutation {
        createWaitlist(arg: {
          firstName: "${name}"
          lastName: "${lastName}"
          email: "${email}"
        }) {
            success
            message
        }
    }
`;
      const backendURL = GetBackendURL();
      axios
        .post(backendURL + mutation)
        .then((res) => {
          if (res.data.data.createWaitlist.success) {
            setSubmitted(true);
          } else {
            setSubmitted(false);
          }
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={
        referrer === "navbar" ? (
          <a href="#">
            <li>Join Waitlist!</li>
          </a>
        ) : referrer === "mobile_navbar" ? (
          <a
            className="nav_mobile_menu_links_login"
            style={{ order: -1, marginBottom: "50px" }}
            onClick={() => callback()}
          >
            <li>Join Waitlist!</li>
          </a>
        ) : referrer === "login_popup" ? (
          <button className="button" onClick={callback}>
            Join waiting list
          </button>
        ) : (
          <button id="join-waiting">Join the Waitlist</button>
        )
      }
      className="waitlist_modal"
    >
      <Form>
        {!submitted ? (
          <div>
            <h3>Join the Waitlist</h3>
            <p>Sign up for the beta and be the first to be notified when we launch</p>

            <div className="flex mb-6 w-full">
              <div class="input-wrapper mr-4">
                <input
                  type="text"
                  id="firstName"
                  required
                  onChange={(e) => setName(e.target.value)}
                />
                <label for="firstName" id="firstName">
                  First Name
                </label>
              </div>

              <div className="input-wrapper">
                <input
                  type="text"
                  id="lastName"
                  required
                  onChange={(e) => setLastName(e.target.value)}
                />
                <label for="lastName" id="lastName">
                  Last Name
                </label>
              </div>
            </div>

            <div className="w-full">
              <div className="input-wrapper w-full">
                <input type="text" id="email" required onChange={(e) => setEmail(e.target.value)} />
                <label for="email" id="email">
                  Email address
                </label>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <img
              id="check-create-waitlist"
              src={check}
              alt="Check"
              style={{
                maxWidth: "256px",
                maxHeight: "256px",
                margin: "auto",
              }}
            />
            <p>Sign up for the beta and be the first to be notified when we launch</p>
          </div>
        )}
      </Form>
      {!submitted ? (
        <Button
          id="join-waiting-button"
          onClick={() => submitEmail()}
          className="join-button disabled:cursor-not-allowed disabled:opacity-30"
          disabled={loading}
        >
          Join the Waitlist
        </Button>
      ) : (
        <Button color="green" onClick={() => setOpen(false)}>
          Go back to homepage
        </Button>
      )}
    </Modal>
  );
}
