import {
  GET_LEVEL,
  GO_UP_LEVEL,
  GET_WAITLIST,
  CREATE_REFERRER,
  SIGNUP_WAITLIST,
  WAITLIST_LOADING,
  WAITLIST_CLEAR_ERROR,
  WAITLIST_ERROR,
} from "../actions/WaitlistActions";

const initialState = {
  loading: false,
  level: 0,
  waitlist: null,
  createRefSuccess: false,
  success: false,
  error: false,
};

const waitlistReducer = function (state = initialState, action) {
  switch (action.type) {
    case WAITLIST_LOADING: {
      return {
        ...state,
        loading: true,
        createRefSuccess: false,
      };
    }
    case GET_LEVEL: {
      return {
        ...state,
        ...action,
        success: true,
        loading: false,
      };
    }
    case GO_UP_LEVEL: {
      return {
        ...state,
        ...action,
        success: true,
        loading: false,
      };
    }
    case GET_WAITLIST: {
      return {
        ...state,
        ...action,
        success: true,
        loading: false,
      };
    }
    case CREATE_REFERRER: {
      let oldList = state.waitlist;
      if (oldList[0].referrers === "") {
        oldList[0].referrers = action.newReferrer;
        oldList[0].statuses = "0";
      } else {
        oldList[0].referrers += "," + action.newReferrer;
        oldList[0].statuses += "," + "0";
      }
      return {
        ...state,
        success: true,
        loading: false,
        waitlist: [
          {
            referrers: oldList[0].referrers,
            statuses: oldList[0].statuses,
          },
        ],
      };
    }
    case SIGNUP_WAITLIST: {
      return {
        ...state,
        ...action,
        success: true,
        loading: false,
      };
    }
    case WAITLIST_CLEAR_ERROR: {
      return {
        ...state,
        success: false,
        error: false,
      };
    }
    case WAITLIST_ERROR: {
      return {
        ...state,
        level: 0,
        loading: false,
        success: false,
      };
    }
    default:
      return state;
  }
};

export default waitlistReducer;
