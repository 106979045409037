import React from "react";
import blogPost from "./img/blogPost.png";

const BlogPosts = () => {
  return (
    <section id="blogPost">
      <div className="mt-48 mb-72 md:grid grid-cols-3 gap-x-12 gap-y-24">
        <div className="text-center sm:text-left mb-28 sm:mb-0 mb-28 sm:mb-0">
          <img src={blogPost} alt="" className="my-0 mx-auto md:mx-0" />
          <h4 className="mt-8 mb-2.5">May 20TH 2020</h4>
          <h3 className="text-2xl	mb-2.5	">Introducing Under the Hood, a New Robinhood Podcast</h3>
          <p className="text-lg mb-8">
            Amidst the economic dowtun, a new need has emerged for Saas companies to ofer more
            flexible payment terms to even the largest...
          </p>
          <button className="w-56 border border-greenButtons rounded-full py-5 text-greenButtons">
            Read more
          </button>
        </div>
        <div className="text-center sm:text-left mb-28 sm:mb-0">
          <img src={blogPost} alt="" className="my-0 mx-auto md:mx-0" />
          <h4 className="mt-8 mb-2.5">May 20TH 2020</h4>
          <h3 className="text-2xl	mb-2.5	">Introducing Under the Hood, a New Robinhood Podcast</h3>
          <p className="text-lg mb-8">
            Amidst the economic dowtun, a new need has emerged for Saas companies to ofer more
            flexible payment terms to even the largest...
          </p>
          <button className="w-56 border border-greenButtons rounded-full py-5 text-greenButtons">
            Read more
          </button>
        </div>
        <div className="text-center sm:text-left mb-28 sm:mb-0">
          <img src={blogPost} alt="" className="my-0 mx-auto md:mx-0" />
          <h4 className="mt-8 mb-2.5">May 20TH 2020</h4>
          <h3 className="text-2xl	mb-2.5	">Introducing Under the Hood, a New Robinhood Podcast</h3>
          <p className="text-lg mb-8">
            Amidst the economic dowtun, a new need has emerged for Saas companies to ofer more
            flexible payment terms to even the largest...
          </p>
          <button className="w-56 border border-greenButtons rounded-full py-5 text-greenButtons">
            Read more
          </button>
        </div>
        <div className="text-center sm:text-left mb-28 sm:mb-0">
          <img src={blogPost} alt="" className="my-0 mx-auto md:mx-0" />
          <h4 className="mt-8 mb-2.5">May 20TH 2020</h4>
          <h3 className="text-2xl	mb-2.5	">Introducing Under the Hood, a New Robinhood Podcast</h3>
          <p className="text-lg mb-8">
            Amidst the economic dowtun, a new need has emerged for Saas companies to ofer more
            flexible payment terms to even the largest...
          </p>
          <button className="w-56 border border-greenButtons rounded-full py-5 text-greenButtons">
            Read more
          </button>
        </div>
        <div className="text-center sm:text-left mb-28 sm:mb-0">
          <img src={blogPost} alt="" className="my-0 mx-auto md:mx-0" />
          <h4 className="mt-8 mb-2.5">May 20TH 2020</h4>
          <h3 className="text-2xl	mb-2.5	">Introducing Under the Hood, a New Robinhood Podcast</h3>
          <p className="text-lg mb-8">
            Amidst the economic dowtun, a new need has emerged for Saas companies to ofer more
            flexible payment terms to even the largest...
          </p>
          <button className="w-56 border border-greenButtons rounded-full py-5 text-greenButtons">
            Read more
          </button>
        </div>
        <div className="text-center sm:text-left mb-28 sm:mb-0">
          <img src={blogPost} alt="" className="my-0 mx-auto md:mx-0" />
          <h4 className="mt-8 mb-2.5">May 20TH 2020</h4>
          <h3 className="text-2xl	mb-2.5	">Introducing Under the Hood, a New Robinhood Podcast</h3>
          <p className="text-lg mb-8">
            Amidst the economic dowtun, a new need has emerged for Saas companies to ofer more
            flexible payment terms to even the largest...
          </p>
          <button className="w-56 border border-greenButtons rounded-full py-5 text-greenButtons">
            Read more
          </button>
        </div>
      </div>
    </section>
  );
};

export default BlogPosts;
