import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { Icon } from "@material-ui/core";
import Auth from "./app/auth/Auth";
import "./styles/tailwind.css";
import "./styles/_app.scss";
import Login from "./app/view/user/Login";
import Register from "./app/view/user/Register";
import PhoneLogin from "./app/view/user/PhoneLogin";
import Verify from "./app/view/user/Verify";
import PhoneVerify from "./app/view/user/PhoneVerify";
import Home from "./app/view/dashboard/Home/Home";
import Performance from "./app/view/main/Performance";
import Withdrawal from "./app/view/main/Withdrawal";
import WithdrawalConfirm from "./app/view/main/WithdrawConfirm";
import Deposit from "./app/view/dashboard/deposit/deposit";
import DepositMobile from "./app/view/dashboard/deposit/DepositMobile";
import DepositAmount from "./app/view/dashboard/deposit/DepositAmount";
import DepositSend from "./app/view/dashboard/deposit/DepositSend";
import DepositSending from "./app/view/dashboard/deposit/DepositSending";
import Potential from "./app/view/profile/Potential";
import Settings from "./app/view/profile/Settings";
import Personal from "./app/view/profile/Personal";
import Wallets from "./app/view/profile/Wallets";
import Agressive from "./app/view/main/Agressive";
import Share from "./app/view/main/Share";
import Faq from "./app/view/main/Faq";
import Landing from "./app/view/main/Landing/Landing";
import AboutUs from "./app/view/main/Landing/About/AboutUs/AboutUs";
import Blog from "./app/view/main/Landing/About/Blog/Blog";
import Careers from "./app/view/main/Landing/About/Careers/Careers";
import Admin from "./app/view/admin/index.jsx";
import NewUser from "./app/view/admin/NewUser";
import AddContribution from "./app/view/admin/AddContribution";
import Withdrawn from "./app/view/admin/Withdrawn";
import DataVisualizer from "./app/view/debug/DataVisualizer";
import NotFound from "./app/view/NotFound";
// import MessengerCustomerChat from "react-messenger-customer-chat";
import { changeBackend } from "./app/redux/actions/AppActions";
import localStorageService from "./app/services/localStorageService";

import "./App.css";

const isDevEnv = !(
  window.location.host.includes("redxam") || window.location.host.includes("5000")
);

function App() {
  const dispatch = useDispatch();
  const [closedDebug, setClose] = useState(false);
  const [loading, setLoading] = useState(true);
  const backend = useSelector((state) => state.app.backend);

  useEffect(() => {
    let theme = localStorageService.getItem("theme");
    if (theme === "dark") document.body.classList.add("dark");

    setLoading(false);
  }, []);

  const handleDB = (backend) => {
    dispatch(changeBackend(backend));
  };

  return (
    <React.Fragment>
      {isDevEnv ? (
        closedDebug ? null : (
          <div
            className="debugmenu"
            style={{
              backgroundColor: "white",
              color: "red",
              position: "fixed",
              minWidth: "50px",
              height: "250px",
              top: "15px",
              right: "15px",
              borderRadius: "21px",
              padding: "25px",
              zIndex: 200,
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
              transition: "width 2s",
            }}
          >
            <div onClick={() => setClose(true)} className="closeDegubMenu">
              <Icon>close</Icon>
            </div>
            <div style={{ width: "100px" }}>
              <h5>You are in the development server.</h5>
              <div>
                <input
                  checked={backend === "prod"}
                  type="radio"
                  id="prod"
                  onChange={(e) => handleDB(e.target.id)}
                />
                <label htmlFor="prod">Prod</label>
                <br />
                <input
                  checked={backend === "dev"}
                  type="radio"
                  id="dev"
                  onChange={(e) => handleDB(e.target.id)}
                />
                <label htmlFor="dev">Dev</label>
                <br />
                <input
                  checked={backend === "local"}
                  type="radio"
                  id="local"
                  onChange={(e) => handleDB(e.target.id)}
                />
                <label htmlFor="local">Local</label>
              </div>
            </div>
            display: {window.screen.width}x{window.screen.height}
          </div>
        )
      ) : null}
      {!loading && (
        <Router>
          <Switch>
            <Route exact path={"/"} component={Landing} />
            <Route exact path={"/login"} component={Login} />
            <Route exact path={"/verify"} component={Verify} />
            <Route exact path={"/phone"} component={PhoneLogin} />
            <Route exact path={"/phone/verify"} component={PhoneVerify} />
            <Route exact path={"/register"} component={Register} />
            <Route exact path={"/data"} component={DataVisualizer} />
            <Route exact path={"/about"} component={AboutUs} />
            <Route exact path={"/blog"} component={Blog} />
            <Route exact path={"/careers"} component={Careers} />
            <Route exact path={"/home"}>
              <Auth>
                <Home />
              </Auth>
            </Route>
            <Route exact path={"/admin"}>
              <Auth>
                <Admin />
              </Auth>
            </Route>
            <Route exact path={"/admin/newuser"}>
              <Auth>
                <NewUser />
              </Auth>
            </Route>
            <Route exact path={"/admin/contribution/add"}>
              <Auth>
                <AddContribution />
              </Auth>
            </Route>
            <Route exact path={"/admin/withdrawn"}>
              <Auth>
                <Withdrawn />
              </Auth>
            </Route>
            <Route exact path={"/withdrawal"}>
              <Auth>
                <Withdrawal />
              </Auth>
            </Route>
            <Route exact path={"/withdrawalconfirm"}>
              <Auth>
                <WithdrawalConfirm />
              </Auth>
            </Route>
            <Route exact path={"/performance"}>
              <Auth>
                <Performance />
              </Auth>
            </Route>
            <Route exact path={"/deposit"}>
              <Auth>
                <Deposit />
              </Auth>
            </Route>
            <Route exact path={"/depositMobile"}>
              <Auth>
                <DepositMobile />
              </Auth>
            </Route>
            <Route exact path={"/depositMobile/amount"}>
              <Auth>
                <DepositAmount />
              </Auth>
            </Route>
            <Route exact path={"/depositMobile/send"}>
              <Auth>
                <DepositSend />
              </Auth>
            </Route>
            <Route exact path={"/depositMobile/sending"}>
              <Auth>
                <DepositSending />
              </Auth>
            </Route>
            <Route exact path={"/portfolio"}>
              <Auth>
                <Agressive />
              </Auth>
            </Route>
            <Route exact path={"/potential"}>
              <Auth>
                <Potential />
              </Auth>
            </Route>
            <Route exact path={"/settings"}>
              <Auth>
                <Settings />
              </Auth>
            </Route>
            <Route exact path={"/personal"}>
              <Auth>
                <Personal />
              </Auth>
            </Route>
            <Route exact path={"/wallets"}>
              <Auth>
                <Wallets />
              </Auth>
            </Route>
            <Route exact path={"/share"}>
              <Auth>
                <Share />
              </Auth>
            </Route>
            <Route exact path={"/faq"}>
              <Auth>
                <Faq />
              </Auth>
            </Route>
            <Route path="*" component={NotFound} />
          </Switch>
        </Router>
      )}
    </React.Fragment>
  );
}

export default App;
