const Plan = () => {
  return (
    <section>
      <div className="plan">
        <div className="plan_header">
          <h2>
            Get the right <br />
            investment Plan.
          </h2>
        </div>
        <div className="plan_pricing">
          <div className="plan_pricing_card">
            <p className="plan_pricing_card_plans">redxam Plans</p>
            <p className="plan_pricing_card_name">Passive</p>
            <ul className="plan_pricing_card_coins">
              <li>
                USDC - <span>33%</span>
              </li>
              <li>
                USDT - <span>33%</span>
              </li>
              <li>
                DAI - <span>33%</span>
              </li>
            </ul>
            <a
              href="https://medium.com/p/bbf8c58e2f7d"
              target="_blank"
              rel="noreferrer noopener"
              className="plan_pricing_card_button"
            >
              Read more
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Plan;
