import React from "react";
import { Icon } from "@material-ui/core";
import { Link } from "react-router-dom";

// Components Imports
import Header from "../main/Header/Header";
import Navbar from "../main/Navbar/Navbar";

// Imgs Imports
import logo from "./settingsimg/logo.png";
import personalArea from "./settingsimg/personalArea.svg";
import depositProfile from "./settingsimg//depositProfile.svg";
import linkedWallets from "./settingsimg/linkedWallets.svg";
import linkedCards from "./settingsimg/linkedCards.svg";
import notifications from "./settingsimg//notifications.svg";
import changePassword from "./settingsimg/changePassword.svg";
import faceID from "./settingsimg/faceID.svg";
import rate from "./settingsimg/rate.svg";
import feedback from "./settingsimg/feedback.svg";
import legal from "./settingsimg/legal.svg";

const Settings = () => {
  return (
    <>
      <div className="container">
        <Header />
        <Navbar title="Settings" backArrowDisplay="block" potentialDisplay="none" />
        <div className="settings_card">
          <div className="settings">
            <div className="settings_group">
              <h2>Personal settings</h2>
              <div className="setting_row">
                <div className="setting_row_img">
                  <img src={personalArea} alt="" />
                </div>
                <div className="setting_row_">
                  <h3>Personal Area</h3>
                </div>
              </div>
              <div className="setting_row">
                <img src={depositProfile} alt="" />
                <h3>Deposit Profile</h3>
              </div>
              <div className="setting_row">
                <img src={linkedWallets} alt="" />
                <h3>Linked Wallet</h3>
              </div>
              <div className="setting_row">
                <img src={linkedCards} alt="" />
                <h3>Linked Cards</h3>
              </div>
            </div>
            <div className="settings_group">
              <h2>Device</h2>
              <div className="setting_row">
                <img src={notifications} alt="" />
                <h3>Notifications</h3>
              </div>
              <div className="setting_row">
                <img src={changePassword} alt="" />
                <h3>Change Password</h3>
              </div>
              <div className="setting_row">
                <img src={faceID} alt="" />
                <h3>Enable Face ID</h3>
                <label className="switch">
                  <input type="checkbox" />
                  <span className="slider"></span>
                </label>
              </div>
            </div>
            <div className="settings_group">
              <h2>Support</h2>
              <div className="setting_row">
                <img src={rate} alt="" />
                <h3>Rate REDXAM</h3>
              </div>
              <div className="setting_row">
                <img src={feedback} alt="" />
                <h3>Send Feedback</h3>
              </div>
              <div className="setting_row">
                <img src={legal} alt="" />
                <h3>Legal</h3>
              </div>
            </div>
            <div className="logout_button">
              <a href="#">Log Out</a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
