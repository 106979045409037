import axios from "axios";
import GetBackendURL from "../../../constants";
import localStorageService from "../../services/localStorageService";

export const HOME_LOADED = "HOME_LOADED";
export const HOME_LOADING = "HOME_LOADING";
export const HOME_ERROR = "HOME_ERROR";
export const PERFORMANCE_FILTER = "PERFORMANCE_FILTER";

export function checkRole(history) {
  return async (dispatch, getState) => {
    dispatch({ type: HOME_LOADING });

    try {
      const homeData = await getHomeData();
      dispatchUser(dispatch, homeData);
      setInterval(async () => {
        const homeData = await getHomeData();
        dispatchUser(dispatch, homeData);
      }, 6000000);
    } catch (err) {
      console.log(err);
      console.log("Err Home dispatch");
      dispatch({
        type: HOME_LOADED,
        success: false,
      });
    }
  };
}

export function queryDeposits(history) {
  return async (dispatch, getState) => {
    try {
      const homepage = await getHomeData();
      const deposits = await getDeposits();

      console.log(homepage);

      dispatchDeposits(dispatch, deposits, homepage);
    } catch (err) {
      console.log(err);
      console.log("Err Deposits dispatch");
      dispatch({
        type: HOME_LOADED,
        success: false,
      });
    }
  };
}

const getHomeData = async () => {
  const token = localStorageService.getItem("token");
  console.log(!token ? "NO TOKEN FOUND!!!!!" : "");
  const backendURL = GetBackendURL();
  const homepage = await getHomepageData(token, backendURL);
  // const vaults = await getVaultsData(backendURL);

  if (!homepage) {
    console.error("SOMETHING IS MISSING IN HERE!!");
  }
  return { homepage };
};

const getDeposits = async () => {
  const token = localStorageService.getItem("token");
  console.log(!token ? "NO TOKEN FOUND!!!!!" : "");
  const backendURL = GetBackendURL();
  const deposits = await getDepositsData(token, backendURL);

  if (!deposits) {
    console.error("DEPOSITS ARE MISSING!!");
  }
  return { deposits };
};

const getHomepageData = async (token, backendURL) => {
  const userQuery = `
  query {
    user {
      email
    }
    home  {
        balance
        dolarChange
        percentChange
    }
}
`;

  return axios.post(backendURL + userQuery, null, {
    headers: { authorization: "Bearer " + token },
  });
};

const getDepositsData = async (token, backendURL) => {
  const depositsQuery = `
  query { 
    user {
      email
    }
    userDeposits {
      type
      amount
      index
      currency
      timestamp
      processedByRedxam
      status
      hash
      address
      bankIcon
      bankName
  } }
`;
  return axios.post(backendURL + depositsQuery, null, {
    headers: { authorization: "Bearer " + token },
  });
};

const getVaultsData = async (backendURL) => {
  const vaultsQuery = `
  query { vaults {
      vaults {
        aave {
          prevBalance
          amount
          balance
          interestRate
          token
          tokenBalance
        }
        harvest {
          prevBalance
          amount
          balance
          interestRate
          token
          tokenBalance
        }
        fulcrum {
          prevBalance
          amount
          balance
          interestRate
          token
          tokenBalance
        }
      }
      type
      totalContribution
    } }
  `;
  return axios.post(backendURL + vaultsQuery);
};

const dispatchUser = (dispatch, { homepage }) => {
  if (homepage.data.data.home) {
    dispatch({
      type: HOME_LOADED,
      payload: { homepage: homepage.data.data.home },
      success: true,
    });
  } else {
    alert("Err dispatch");
    dispatch({
      type: HOME_LOADED,
      success: false,
    });
  }
};

const dispatchDeposits = (dispatch, { deposits }, { homepage }) => {
  if (deposits.data.data || homepage.data.data.home) {
    dispatch({
      type: HOME_LOADED,
      payload: { homepage: homepage.data.data.home, deposits: deposits.data.data },
      success: true,
    });
  } else {
    alert("Err dispatch");
    dispatch({
      type: HOME_LOADED,
      success: false,
    });
  }
};
