import { HOME_LOADED, HOME_LOADING, HOME_ERROR, PERFORMANCE_FILTER } from "../actions/HomeActions";

const initialState = {
  loading: true,
  backend: true,
  home: null,
  success: false,
  error: false,
};

const homeReducer = function (state = initialState, action) {
  switch (action.type) {
    case HOME_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case HOME_LOADED: {
      return {
        ...state,
        loading: false,
        home: action.payload,
      };
    }
    case HOME_ERROR: {
      return {
        ...state,
        loading: false,
        ...action,
      };
    }

    case PERFORMANCE_FILTER: {
      console.log(action);
      const performanceRecords = action.performanceRecords ? action.performanceRecords : null;
      console.log(performanceRecords);
      return {
        ...state,
        user: { ...state.user, performanceRecords },
      };
    }

    default:
      return state;
  }
};

export default homeReducer;
