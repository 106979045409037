import React from "react";
import logo from "./logo.png";
import "./_Header.sass";
const Header = () => {
  return (
    <header id="header">
      <img src={logo} alt="Logo redxam" />
    </header>
  );
};

export default Header;
