import React, { useState } from "react";
import startImg1 from "./img/start-img1.png";
import startImg2 from "./img/start-img2.png";
import startImg3 from "./img/start-img3.png";

const Starting = () => {
  const [step, setStep] = useState(1);
  const [startImg, setStartImg] = useState(startImg1);

  const handleStep = (i) => {
    setStep(i);
    if (i === 1) setStartImg(startImg1);
    else if (i === 2) setStartImg(startImg2);
    else setStartImg(startImg3);
  };

  return (
    <section>
      <div className="starting">
        <h4>how to start</h4>
        <h2>Starting is very Simple</h2>
        <p>Start investing with redxam in three simple steps</p>

        <div className="start">
          <div className="start_img">
            <img src={startImg} alt="Deposit redxam Mobile App" />
          </div>
          <div className="start_steps">
            <div
              className={step === 1 ? "start_step active" : "start_step"}
              onMouseEnter={() => handleStep(1)}
            >
              <div className="start_step_number">
                <p>1</p>
              </div>
              <div className="start_step_text">
                <h3>Create an Account</h3>
                <p>Signup for an account with just name and email.</p>
              </div>
            </div>
            <span className="start_step_bar"></span>
            <div
              className={step === 2 ? "start_step active" : "start_step"}
              onMouseEnter={() => handleStep(2)}
            >
              <div className="start_step_number">
                <p>2</p>
              </div>
              <div className="start_step_text">
                <h3>Deposit Funds</h3>
                <p>
                  Choose preferred deposit option like bank transfer, credit/debit card or directly
                  send digital assets.
                </p>
              </div>
            </div>
            <div
              className={step === 3 ? "start_step active" : "start_step"}
              onMouseEnter={() => handleStep(3)}
            >
              <div className="start_step_number">
                <p>3</p>
              </div>
              <div className="start_step_text">
                <h3>Watch your money Grow</h3>
                <p>Sit back and relax! Let your let your money work for you all day.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Starting;
