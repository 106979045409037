import React from "react";
import { useHistory } from "react-router-dom";

// Imgs Import
import signout from "./signout.svg";
import backArrow from "./backarrow.svg";

import "./_Navbar.sass";

const Navbar = ({ title, backArrowDisplay, potentialDisplay }) => {
  const history = useHistory();

  return (
    <nav id="navbar">
      <div>
        <div>
          <img
            src={backArrow}
            alt=""
            style={{ display: backArrowDisplay, cursor: "pointer" }}
            onClick={() => history.goBack()}
          />
          <span>{title}</span>
        </div>
        <div style={{ display: potentialDisplay }}>
          <div
            style={{ flex: 1 }}
            onClick={() => {
              history.push("/");
            }}
          >
            Sign out
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
