import logo from "./footer_logo.svg";
const Footer = (props) => {
  return (
    <div className="flex flex-col items-center justify-center py-10">
      <img src={logo} alt="bottom logo" />
    </div>
  );
};

export default Footer;
