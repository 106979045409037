import React from "react";
import Hero from "./Hero";
import BlogPosts from "./BlogPosts";
import Newsletter from "../../Newsletter";

const Blog = () => {
  return (
    <div className="landing about-landing-content">
      <div className="container">
        <Hero />
        <BlogPosts />
      </div>
      <Newsletter />
    </div>
  );
};

export default Blog;
