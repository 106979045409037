import React from "react";
import workWithUs1 from "./img/workwithus1.png";
import workWithUs2 from "./img/workwithus2.png";
import { Link } from "react-router-dom";

const WorkWithUs = () => {
  return (
    <section className="workwithus lg:flex justify-between mb-56 lg:pr-16">
      <div className="lg:w-2/5 lg:mr-14 text-center lg:text-left mb-16 lg:mb-0">
        <h2 className="mb-9">Interested in working with US?</h2>
        <p className="mb-10 lg:w-4/5">
          As a team of passionate entrepreneurs, we built Pipe around our core mission to create a
          new way to scale without debt or dilution, so companies can grow on their terms.
        </p>
        <Link to="/careers">
          <button className="bg-greenButtons py-5 px-16 rounded-full">View Job openings</button>
        </Link>
      </div>
      <div className="lg:flex relative">
        <img
          src={workWithUs1}
          alt=""
          className="mx-auto lg:mx-0 mb-16 lg:mb-0 lg:absolute right-64 -bottom-8"
        />
        <img src={workWithUs2} alt="" className="mx-auto lg:mx-0" />
      </div>
    </section>
  );
};

export default WorkWithUs;
