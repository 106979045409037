import React, { useEffect, useRef, useState } from "react";
import { InputAdornment, Button, Icon, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import MuiPhoneNumber from "material-ui-phone-number";
import Loading from "../../../components/DisappearLoading";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import { getUsers } from "../../redux/actions/UserActions";
import Internal from "../../../components/layouts/Internal";

const AddContribution = () => {
  const dispatch = useDispatch();
  const newContributionRef = useRef();

  const [contribution, setContribution] = useState({
    id: null,
    type: "CRYPTO",
    currency: "BTC",
    amount: 0,
  });
  const [types, setTypes] = useState(["CRYPTO"]);
  const [currencies, setCurrencies] = useState(["BTC"]);
  const [first, setFirst] = useState(true);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);

  const manualUserData = useSelector((state) => {
    return state.manual_user;
  });

  const users = useSelector((state) => {
    return state.user;
  });

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  useEffect(() => {
    if (users.users !== null) {
      if (users.users.length === 0) {
        setContribution({
          ...contribution,
          id: -1,
        });
      } else {
        setContribution({
          ...contribution,
          id: users.users[0]._id,
        });
      }
    }
  }, [users.users]);

  useEffect(() => {
    if (manualUserData.success) {
      setOpen(true);
      setMessage(manualUserData.error);
      setSuccess(true);
      setFirst(false);
    }
    if (!manualUserData.success && manualUserData.error) {
      setOpen(true);
      setMessage(manualUserData.error);
      setSuccess(false);
      setFirst(false);
    }
    setContribution({ type: "CRYPTO", currency: "BTC", amount: 0 });
  }, [manualUserData.success, manualUserData.error]);

  const handleSubmit = () => {};

  const handleChange = (event) => {
    event.persist();
    setContribution({
      ...contribution,
      [event.target.name]: event.target.value,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Internal>
      <div className="container loginContainer newUserContainer">
        {(manualUserData.loading || users.loading) && <Loading />}
        <div className="contentCenter newUserLogo">
          <img src="/assets/images/short_logo.png" />
        </div>
        <h1 className="text-center title">New Contribution</h1>
        <Snackbar
          open={open && !first}
          className="toastDiv"
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={handleClose} severity={success ? "success" : "error"}>
            {message}
          </Alert>
        </Snackbar>
        {contribution.id === -1 && <p>No user</p>}
        {contribution.id !== -1 && (
          <ValidatorForm
            ref={newContributionRef}
            onSubmit={handleSubmit}
            onError={(errors) => null}
            className="profileForm addContributionForm"
          >
            {!contribution.id && <p>Loading for users list...</p>}
            {contribution.id && (
              <TextValidator
                className="emailInput registerEmail selectCurrency"
                select
                label="Currency"
                variant="outlined"
                name="id"
                value={contribution.id}
                onChange={handleChange}
                validators={["required"]}
                errorMessages={["This field is required"]}
              >
                {users.users.map((option, key) => (
                  <MenuItem key={key} value={option._id}>
                    {option.email}
                  </MenuItem>
                ))}
              </TextValidator>
            )}
            <TextValidator
              className="emailInput registerEmail selectCurrency"
              select
              name="type"
              label="Type"
              variant="outlined"
              value={contribution.type}
              onChange={handleChange}
              validators={["required"]}
              errorMessages={["This field is required"]}
            >
              {types.map((option, key) => (
                <MenuItem key={key} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextValidator>
            <TextValidator
              className="emailInput registerEmail selectCurrency"
              select
              label="Currency"
              name="currency"
              variant="outlined"
              value={contribution.currency}
              onChange={handleChange}
              validators={["required"]}
              errorMessages={["This field is required"]}
            >
              {currencies.map((option, key) => (
                <MenuItem key={key} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextValidator>
            <TextValidator
              className="emailInput registerEmail contributionAmount"
              label="Name"
              onChange={handleChange}
              type="number"
              name="amount"
              variant="outlined"
              value={contribution.amount || 0}
              validators={["required"]}
              errorMessages={["This field is required"]}
            />
            <div className="registerBtn">
              <Button variant="contained" color="primary" type="submit" className="loginBtn">
                <Icon>add</Icon>&nbsp;<span className="pl-8 capitalize">Add</span>
              </Button>
            </div>
          </ValidatorForm>
        )}
      </div>
    </Internal>
  );
};

export default AddContribution;
