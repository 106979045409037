import React from "react";
import backArrow from "./backArrow.svg";
import cancelButton from "../../../../images/cancelButton.svg";

const MobileNavbar = ({ back, name, cancel }) => {
  return (
    <nav className="flex justify-between mb-6 pt-6">
      <button onClick={() => window.history.back()}>
        <img className={back} src={backArrow} alt="" />
      </button>
      <p>{name}</p>
      {cancel && (
        <button>
          <img className={cancel} src={cancelButton} alt="" />
        </button>
      )}
    </nav>
  );
};

export default MobileNavbar;
