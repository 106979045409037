import { combineReducers } from "redux";
import UserReducer from "./UserReducer";
import ManualUserReducer from "./ManualUserReducers";
import HomeReducer from "./HomeReducers";
import AppReducer from "./AppReducers";
import Waitlist from "./WaitlistReducer";

const RootReducer = combineReducers({
  app: AppReducer,
  user: UserReducer,
  home: HomeReducer,
  waitlist: Waitlist,
  manual_user: ManualUserReducer,
});

export default RootReducer;
