import logo from "./header_logo.svg";

const Header = ({ title }) => {
  return (
    <div className="header__background h-40 space-y-5 rounded-b-3xl">
      <img src={logo} alt="logo" />
      <span className="font-medium text-white text-2xl">{title}</span>
    </div>
  );
};

export default Header;
