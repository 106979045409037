import Store from "../app/redux/Store";

const GetBackendURL = function (props) {
  let backend = Store.getState();
  backend = backend.app.backend;
  switch (backend) {
    case "prod":
      return process.env.REACT_APP_BACKEND_URL;
    case "dev":
      return process.env.REACT_APP_DEV_BACKEND_URL;
    case "local":
      return process.env.REACT_APP_LOCAL_BACKEND_URL;
    default:
      return process.env.REACT_APP_BACKEND_URL;
  }
};

export const GetBackendURL_V2 = function (props) {
  let backend = Store.getState();
  backend = backend.app.backend;
  switch (backend) {
    case "prod":
      return process.env.REACT_APP_BACKEND_URL_V2;
    case "dev":
      return process.env.REACT_APP_DEV_BACKEND_URL_V2;
    case "local":
      return process.env.REACT_APP_LOCAL_BACKEND_URL_V2;
    default:
      return process.env.REACT_APP_BACKEND_URL_V2;
  }
};

export default GetBackendURL;
