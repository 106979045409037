import React from "react";

// Imgs Import
import trendingup from "./TrendingUpOutline.svg";

import "./_Navbar.sass";

const NavbarMobile = ({ title }) => {
  return (
    <nav id="navbarMobile">
      <div className="config-name">
        <p>Welcome 🎉!</p>
      </div>
      <div className="potential">
        <a href="#">
          <img src={trendingup} alt="" />
        </a>
      </div>
    </nav>
  );
};

export default NavbarMobile;
