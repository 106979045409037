import axios from "axios";
import { browserHistory } from "react-router";
import { router } from "react-router-dom";
import GetBackendURL from "../../../constants";

export const MANUAL_USER_CREATE = "MANUAL_USER_CREATE";
export const MANUAL_USER_LOADING = "MANUAL_USER_LOADING";
export const MANUAL_USER_CLEAR_ERROR = "MANUAL_USER_CLEAR_ERROR";
export const MANUAL_USER_ERROR = "MANUAL_USER_ERROR";

export function createManualUser(user) {
  return (dispatch, getState) => {
    dispatch({ type: MANUAL_USER_LOADING });
    const mutation = `
            mutation {
                createManualUser(arg: {name: "${user.name}", email: "${user.email}", phone: "${user.phone}"}) {
                    success
                    message
                }
            }
        `;
    const backendURL = GetBackendURL();
    axios
      .post(backendURL + mutation)
      .then((res) => {
        if (res.data.data.createManualUser.success) {
          dispatch({
            type: MANUAL_USER_CREATE,
            success: true,
            error: res.data.data.createManualUser.message,
          });
          setTimeout(() => {
            dispatch({ type: MANUAL_USER_CLEAR_ERROR });
          }, 500);
        } else {
          dispatch({
            type: MANUAL_USER_ERROR,
            error: res.data.data.createManualUser.message,
          });
          setTimeout(() => {
            dispatch({ type: MANUAL_USER_CLEAR_ERROR });
          }, 500);
        }
      })
      .catch((err) => {
        dispatch({
          type: MANUAL_USER_ERROR,
          success: false,
          error: "Network Error. Please try again later.",
        });
        setTimeout(() => {
          dispatch({ type: MANUAL_USER_CLEAR_ERROR });
        }, 500);
      });
  };
}
