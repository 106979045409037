import React from "react";
import investImg1 from "./img/invest-img1.svg";
import investImg2 from "./img/invest-img2.svg";
import investImg3 from "./img/invest-img3.svg";
import waves from "./img/waves2.svg";
import localStorageService from "../../../../../services/localStorageService.js";

const InvestingWay = () => {
  return (
    <section className="relative">
      <div className="mt-12 pt-48 lg:pt-64" id="investingWay">
        <h2 className="text-center">We are changing the Way of Investing</h2>
        <div className="md:flex items-center justify-center mb-12 text-center md:text-left">
          <img src={investImg1} alt="" />
          <div className="md:ml-14 md:w-1/3">
            <h3 className="mb-3 ">Changing the way people invest</h3>
            <p>
              We’re redefining what it means to learn about finance—and that means education
              resources that are built for today
            </p>
          </div>
        </div>
        <div className="flex flex-col-reverse md:flex-row items-center justify-center mb-12 text-center md:text-left">
          <div className="md:mr-14 md:w-1/3">
            <h3 className="mb-3 ">We are building teams around the world bit by bit.</h3>
            <p>
              Start investing at your own pace, on your own terms.Start investing at your own pace,
              on your own terms. Start investing at your own pace, on your own terms.
            </p>
          </div>
          <img src={investImg2} alt="" />
        </div>
        <div className="md:flex items-center justify-center text-center md:text-left">
          <img src={investImg3} alt="" />
          <div className="md:ml-14 md:w-1/3">
            <h3 className="mb-3 ">Let’s do the work while you rest.</h3>
            <p>
              Start investing at your own pace, on your own terms.Start investing at your own pace,
              on your own terms. Start investing at your own pace, on your own terms.
            </p>
          </div>
        </div>
      </div>
      <img
        src={waves}
        className="absolute w-screen h-screen right-0 dark:hidden"
        style={{
          top: "12rem",
          zIndex: -1,
          display: localStorageService.getItem("theme") === "dark" ? "none" : "",
        }}
      />
    </section>
  );
};

export default InvestingWay;
