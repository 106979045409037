import React, { useState, useEffect } from "react";
import axios from "axios";
import { GetBackendURL_V2 } from "../../../../constants";
import localStorageService from "../../../services/localStorageService";

import KYCView from "../kyc/KYCView";

const BankDepositController = (props) => {
  const [isApplicant, setIsApplicant] = useState(false);
  const [isValidApplicant, setIsValidApplicant] = useState(false);
  const [isInit, setIsInit] = useState(null);
  useEffect(() => {
    const getApplicantData = async () => {
      const token = localStorageService.getItem("token");
      const backendURL = GetBackendURL_V2();

      const res = await axios.post(`${backendURL}/applicantData`, {
        userToken: token,
      });
      if (res.data.status === 200) {
        setIsApplicant(true);
        if (!res.data.review) return null;
        setIsInit(res.data.review.reviewStatus === "init");
        if (res.data.review.reviewResult)
          setIsValidApplicant(res.data.review.reviewResult.reviewAnswer === "GREEN");
      }
    };
    getApplicantData();
  }, []);
  return (
    <div>
      {!isApplicant && <p style={{ textAlign: "center" }}>Please complete KYC Verification</p>}
      {isApplicant && !isValidApplicant && !isInit && (
        <p style={{ textAlign: "center" }}>Oops something went wrong</p>
      )}
      {isInit && <p style={{ textAlign: "center" }}>Please finish KYC Verification</p>}
      {isValidApplicant && <p style={{ textAlign: "center" }}>{props.plaid}</p>}
      {!isValidApplicant && <KYCView />}
    </div>
  );
};

export default BankDepositController;
