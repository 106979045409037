import axios from "axios";
import { useState } from "react";
import GetBackendURL from "../../../constants";
import localStorageService from "../../services/localStorageService";
import logo from "./img/logo.svg";
import CloseIcon from "@material-ui/icons/CloseRounded";

export default function StaffLogin({ setLoggedIn, setAdminInfo }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) return alert("Email is required!");
    if (!password) return alert("Password is required!");

    setError("");
    setLoading(true);
    const backendURL = GetBackendURL();

    try {
      let { data } = await axios.post(backendURL + "query { adminLogin { token } }", {
        email,
        password,
      });

      localStorageService.setItem("admin_token", data.data.adminLogin.token);
      setAdminInfo({ email });
      setLoggedIn(true);
    } catch (error) {
      console.error(error);
      setError("Invalid Email or Password");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="flex items-center justify-center h-44">
        <img src={logo} />
      </div>
      <div className="border border-gray-300 rounded-2xl max-w-2xl mx-auto flex flex-col py-8 px-8">
        <h1 className="text-3xl text-center mt-2">Admin Login</h1>
        {error && (
          <span
            style={{ backgroundColor: "rgba(255,0,0,0.2)", borderLeft: "2px solid red" }}
            className="mt-12 flex items-center justify-between text-lg text-primaryBlack py-4 px-6 rounded-lg"
          >
            <span>{error}</span>
            <span
              className="border border-red-900 rounded-full p-1 flex items-center justify-center cursor-pointer"
              onClick={() => setError(null)}
            >
              <CloseIcon />
            </span>
          </span>
        )}
        <form className="mt-12 px-8 flex flex-col" onSubmit={handleSubmit}>
          <input
            type="email"
            name="email"
            placeholder="Email or username"
            className="px-8 py-3 border border-gray-200 rounded-full w-full outline-none focus:shadow focus:border-2 font-extralight"
            required
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            name="password"
            placeholder="Password"
            className="px-8 py-3 border border-gray-200 rounded-full w-full outline-none focus:shadow focus:border-2 font-extralight mt-4"
            required
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className="flex px-3 mt-8">
            <div className="flex-1 flex items-center">
              <input type="checkbox" name="rememberme" id="rememberme" />
              <label htmlFor="rememberme" className="mb-0 ml-1">
                Remember me
              </label>
            </div>
            <div className="flex-1 flex justify-end">
              <a href="/" style={{ color: "rgba(62,180,2,1)" }}>
                Reset password
              </a>
            </div>
          </div>
          <input
            type="submit"
            value="Submit"
            className={`text-white rounded-full px-12 py-3 mt-32 self-center transition-opacity duration-300 hover:opacity-70 ${
              loading ? "opacity-30 pointer-events-none" : ""
            }`}
            style={{ backgroundColor: "rgba(62,180,2,1)" }}
          />
        </form>
      </div>
      <ul className="flex flex-row justify-around max-w-lg mx-auto mt-32 opacity-50 font-extralight">
        <li>
          <a href="/">Privacy</a>
        </li>
        <li>
          <a href="/">Terms and Conditions</a>
        </li>
        <li>
          <a href="/">Contact us</a>
        </li>
      </ul>
      <div className="absolute bottom-0 right-0" style={{ zIndex: -1 }}>
        <svg
          width="948"
          height="929"
          viewBox="0 0 948 929"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_f)">
            <ellipse
              cx="777.5"
              cy="766.5"
              rx="377.5"
              ry="366.5"
              fill="#54AE70"
              fill-opacity="0.2"
            />
          </g>
          <defs>
            <filter
              id="filter0_f"
              x="0"
              y="0"
              width="1555"
              height="1533"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feGaussianBlur stdDeviation="200" result="effect1_foregroundBlur" />
            </filter>
          </defs>
        </svg>
      </div>
    </>
  );
}
