import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Store } from "./app/redux/Store";
import "semantic-ui-css/semantic.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import words_en from "./translations/en/index";
import words_es from "./translations/es/index";
import "./index.css";

i18next.init({
  interpolation: { escapeValue: false },
  lng: "en",
  resources: {
    en: {
      common: words_en,
    },
    es: {
      common: words_es,
    },
  },
});

ReactDOM.render(
  <React.Fragment>
    <I18nextProvider i18n={i18next}>
      <Provider store={Store}>
        <App />
      </Provider>
    </I18nextProvider>
  </React.Fragment>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
