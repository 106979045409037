import Header from "../core/Header";
import Footer from "../core/Footer";
import { Container } from "reactstrap";
import "./Internal.css";

const InternalLayout = ({ children, title }) => {
  return (
    <Container className="internal" fluid={true}>
      <Header title={title} />
      <div className="pt-5">{children}</div>
      <Footer />
    </Container>
  );
};

export default InternalLayout;
