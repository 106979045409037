/* eslint-disable spellcheck/spell-checker */
import React, { useState, useEffect } from "react";
// import { isBrowser, isMobile } from "react-device-detect";

// Components imports
import { Container } from "reactstrap";
import MobileNavbar from "../../main/MobileNavbar/MobileNavbar";

// Imgs Imports
import arrow from "./img/arrow.svg";
import loader from "./img/loader.svg";

const DepositSending = () => {
  const [calculateFee, setCalculateFee] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      //console.log("This will run after 1 second!");
      setCalculateFee(false);
    }, 5000);
  }, []);

  return (
    <Container className="dashboard" fluid={true}>
      <div className="h-screen relative flex flex-col">
        <MobileNavbar cancel="block" name="Sending" />

        <div className="flex flex-col items-center mt-14 h-full">
          <p className="text-6xl text-primaryGreen font-medium mb-1.5">$500</p>
          <p className="opacity-50 primaryBlack text-sm mb-10">BTC 0,0090</p>
          <p className="mb-5 text-primaryBlack text-base font-medium">From</p>
          <p className="text-primaryBlack font-normal text-sm uppercase ">redxam</p>
          <img src={arrow} alt="Down Arrow" className="my-8" />
          <p className="mb-5 text-primaryBlack text-base font-medium">To</p>
          <p className="text-primaryBlack font-normal text-sm mb-8">
            0asd54a654sda1sd231a0sa545400
          </p>
          {calculateFee === true ? (
            <>
              <img src={loader} alt="Loader" className="mb-4" />
              <p className="text-primaryBlack opacity-50 font-normal text-xs">Calculating fee</p>
            </>
          ) : (
            <>
              <p className="mb-5 text-primaryBlack text-base font-medium">Fee</p>
              <p className="text-primaryGreen text-base font-medium mb-2.5">$50</p>
              <p className="opacity-50 font-normal text-sm text-primaryBlack">BTC 0,00090</p>
              <div className="flex justify-center w-full h-full mb-14">
                <button
                  className="self-end w-full text-white py-5 rounded-full"
                  style={{
                    background: "linear-gradient(180deg, #272B22 0%, #353930 100%)",
                    boxShadow:
                      "0px 20px 13px rgba(39, 43, 34, 0.1), 0px 8.14815px 6.51852px rgba(39, 43, 34, 0.05), 0px 1.85185px 3.14815px rgba(39, 43, 34, 0.025)",
                  }}
                >
                  Send
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </Container>
  );
};

export default DepositSending;
