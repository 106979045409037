import React, { useEffect, useRef, useState } from "react";
import { InputAdornment, Button, Icon, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import MuiPhoneNumber from "material-ui-phone-number";
import Loading from "../../../components/DisappearLoading";
import { createManualUser } from "../../redux/actions/ManualUserActions";
import Internal from "../../../components/layouts/Internal";

const NewUser = () => {
  const dispatch = useDispatch();
  const newUserRef = useRef();

  const [user, setUser] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [first, setFirst] = useState(true);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);

  const manualUserData = useSelector((state) => {
    return state.manual_user;
  });

  useEffect(() => {
    if (manualUserData.success) {
      setOpen(true);
      setMessage(manualUserData.error);
      setSuccess(true);
      setFirst(false);
    }
    if (!manualUserData.success && manualUserData.error) {
      setOpen(true);
      setMessage(manualUserData.error);
      setSuccess(false);
      setFirst(false);
    }
    setUser({ email: "", phone: "", name: "" });
  }, [manualUserData.success, manualUserData.error]);

  const handleSubmit = () => {
    dispatch(createManualUser(user));
  };

  const handleChange = (event) => {
    event.persist();
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    });
  };

  const handlePhoneBefore = (value) => {
    const phoneNumber = value
      .replaceAll(" ", "")
      .replaceAll("(", "")
      .replaceAll(")", "")
      .replaceAll("-", "");
    setUser({
      ...user,
      phone: phoneNumber,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Internal>
      <div className="container loginContainer newUserContainer">
        {manualUserData.loading && <Loading />}
        <div className="contentCenter newUserLogo">
          <img src="/assets/images/short_logo.png" />
        </div>
        <h1 className="text-center title">New User</h1>
        <Snackbar
          open={open && !first}
          className="toastDiv"
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={handleClose} severity={success ? "success" : "error"}>
            {message}
          </Alert>
        </Snackbar>
        <ValidatorForm
          ref={newUserRef}
          onSubmit={handleSubmit}
          onError={(errors) => null}
          className="profileForm"
        >
          <TextValidator
            className="emailInput registerEmail"
            label="Name"
            onChange={handleChange}
            type="text"
            name="name"
            variant="outlined"
            value={user.name || ""}
            validators={["required"]}
            errorMessages={["This field is required"]}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" className="emailBefore">
                  <Icon>person</Icon>
                </InputAdornment>
              ),
            }}
          />
          <TextValidator
            className="emailInput registerEmail phoneInput"
            label="Email"
            onChange={handleChange}
            type="email"
            name="email"
            variant="outlined"
            value={user.email}
            validators={["required", "isEmail"]}
            errorMessages={["This field is required", "Email is not valid"]}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" className="emailBefore">
                  <Icon>email</Icon>
                </InputAdornment>
              ),
            }}
          />
          <MuiPhoneNumber
            className="emailInput phoneInput"
            label="Phone Number"
            defaultCountry={"us"}
            onChange={handlePhoneBefore}
            variant="outlined"
            validators={["required"]}
            errormessages={["This field is required"]}
          />
          <div className="registerBtn">
            <Button variant="contained" color="primary" type="submit" className="loginBtn">
              <Icon>add</Icon>&nbsp;<span className="pl-8 capitalize">Save</span>
            </Button>
          </div>
        </ValidatorForm>
      </div>
    </Internal>
  );
};

export default NewUser;
