import React from "react";
import whiteScroll from "./img/white-scroll.svg";
import {Link} from 'react-scroll';

const Hero = () => {
  return (
    <section>
      <div className="hero justify-start md:mt-24 2xl:mt-40">
        <h1 className="text-white text-center lg:w-3/5">
          We are creating the Future of Crypto Investment.
        </h1>

        <p className="text-white">
          We build smart algorithm strategies to maximize your ROI. Our simplicity, yet stylish and
          safe platform allows you to invest whether you are an individual or an institution, we help
          you buy, sell and store your cryptocurrency
        </p>
        <Link className="cursor" to="investingWay" spy={true} smooth={true}> 
          <img src={whiteScroll} alt="Scroll Down" />
        </Link>
      </div>
    </section>
  );
};

export default Hero;
