import React, { useRef, useState } from "react";
import { Icon, Link, Button, InputAdornment } from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Internal from "../../../components/layouts/Internal";

const WithdrawalConfirm = () => {
  const formRef = useRef();
  const [amount, setAmount] = useState("");
  const [success, setSuccess] = useState(false);

  const handleSubmit = () => {
    setSuccess(true);
    //console.log("Submitted!");
  };

  const handleChange = (event) => {
    event.persist();
    setAmount(event.target.value);
  };

  return (
    <Internal>
      <div className="withdrawContainer">
        {success && (
          <div>
            <div className="iconWarning contentCenter">
              <Icon>error</Icon>
            </div>
            <h2 className="text-center">Congrats</h2>
            <div className="withdrawSuccessMessage">
              Rhoncus porttitor mattis egestas maecenas habitasse at iaculis ligula amet nunc!
            </div>
            <div className="withdrawBtn continueBtn contentCenter successOK">
              <Link href="/home">OK</Link>
            </div>
          </div>
        )}
        {!success && (
          <ValidatorForm
            ref={formRef}
            onSubmit={handleSubmit}
            onError={(errors) => null}
            className="profileForm"
          >
            <TextValidator
              className="withdrawInput"
              onChange={handleChange}
              type="text"
              name="amount"
              value={amount}
              validators={["required", "minNumber:0"]}
              errorMessages={["This field is required", "Invalid Amount"]}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" className="dollarSign">
                    $
                  </InputAdornment>
                ),
              }}
            />
            <div className="withdrawBtn cancelBtn contentCenter">
              <Button variant="contained" color="primary" type="submit" className="withdrawSubmit">
                Withdraw
              </Button>
            </div>
            <div className="withdrawBtn cancelBtn contentCenter">
              <Link href="/home">Cancel</Link>
            </div>
          </ValidatorForm>
        )}
      </div>
    </Internal>
  );
};

export default WithdrawalConfirm;
