/* eslint-disable spellcheck/spell-checker */
import React from "react";
import logoImg from "./img/redxam-logo.svg";
import linkedinImg from "./img/linkedin.svg";
import twitterImg from "./img/twitter.svg";
import facebookImg from "./img/facebook.svg";
import Pdf from "../../../../files/pdfs/Terms of service.pdf";
import Txt from "../../../../files/txts/Privacy Policy.txt";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer_info">
          <div className="footer_info_cl">
            <div className="footer_info_cl_logo">
              <img src={logoImg} alt="redxam Logo" />
              <h2>redxam</h2>
            </div>
            <p className="footer_info_cl_copy">© 2021 redxam, Inc.</p>
          </div>
          <div className="footer_info_menu">
            <div className="footer_info_menu_cl">
              <h4>links</h4>
              <ul>
                <a href="/about">
                  <li>About</li>
                </a>
                <a href="/#faq">
                  <li>Faq</li>
                </a>
                <a href="/careers">
                  <li>Careers</li>
                </a>
              </ul>
            </div>
            <div className="footer_info_menu_cl">
              <h4>privacy</h4>
              <ul>
                <a href={Pdf} target="_blank" rel="noreferrer">
                  <li>Terms of Service</li>
                </a>
                <a href={Txt} target="_blank" rel="noreferrer">
                  <li>Privacy policy</li>
                </a>
              </ul>
            </div>
            <div className="footer_info_menu_cl">
              <h4>reach out</h4>
              <ul>
                <a href="mailto:hello@redxam.com?body=Right%20us%20about%20anything%20but%20specially%20redxam%20%F0%9F%98%81!%0A%0AWe%20are%20here%20to%20help!">
                  <li>hello@redxam.com</li>
                </a>
                <a href="tel:1-973-626-4505">
                  <li>+1 973 626 4505</li>
                </a>
              </ul>
              <div className="social_media">
                <a href="https://linkedin.com/company/redxam">
                  <img src={linkedinImg} alt="LinkedIn Icon" />
                </a>
                <a href="https://facebook.com/redxamenglish" target="_blank" rel="noreferrer">
                  <img src={facebookImg} alt="Facebook Icon" />
                </a>
                <a href="https://twitter.com/redxamapp">
                  <img src={twitterImg} alt="Twitter Icon" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_trademarks">
        <p>
          All product and company names are trademarks or registered trademarks of their respective
          holders.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
