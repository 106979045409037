import React from "react";
import depositImg from "./img/deposit-icon.svg";
import clockImg from "./img/clock-icon.svg";
import wealthImg from "./img/wealth-icon.svg";
import qualityImg from "./img/quality-icon.svg";

const WhyUs = () => {
  return (
    <section id="benefits">
      <div className="why_us mt-24 mb-48">
        <h2>Why you should Invest with redxam</h2>
        <div className="benefits">
          <div className="benefits_card">
            <div className="benefits_card_img">
              <img src={depositImg} alt="deposit" />
            </div>
            <div className="benefits_card_text">
              <h3>Deposit and Withdraw at Anytime</h3>
              <p>
                Designed to help you own your future. Tell us how wealthy you want to be at
                retirement and we’ll build a personalized investment plan for you.
              </p>
            </div>
          </div>

          <div className="benefits_card">
            <div className="benefits_card_img">
              <img src={clockImg} alt="clock" />
            </div>
            <div className="benefits_card_text">
              <h3>No Transaction Fees</h3>
              <p>
                Get guidance in your investing journey and feel more confident in your decisions.
                Talk to our experts anytime and dive deeper into your personal finance.
              </p>
            </div>
          </div>

          <div className="benefits_card">
            <div className="benefits_card_img">
              <img src={wealthImg} alt="wealth" />
            </div>
            <div className="benefits_card_text">
              <h3>Wealth Management System</h3>
              <p>
                It's not just money, it's superior wealth management. We handle this so you can
                focus on what you're best at.
              </p>
            </div>
          </div>

          <div className="benefits_card">
            <div className="benefits_card_img">
              <img src={qualityImg} alt="quality" />
            </div>
            <div className="benefits_card_text">
              <h3>Highest Quality Service</h3>
              <p>
                We spend a great deal of time to ensure the coins you get on redxam are among the
                highest quality anywhere.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
